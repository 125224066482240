import { Fragment } from 'react';

export const ModelSkeletonLoader = () => {
  const skeleton = (height?: string, width?: string, radius?: string) => {
    return (
      <div
        className='loading-skeleton'
        style={{
          height: height,
          width: width,
          borderRadius: radius,
        }}
      />
    );
  };

  return (
    <div className='mb-20'>
      <div className='h-[60px] px-6 flex items-center justify-between'>
        <div className='flex w-full items-center gap-x-2'>
          {skeleton('32px', '32px', '4px')}
          {skeleton('28px', '40%', '6px')}
        </div>
        <div className='flex items-center gap-x-4'>
          {skeleton('36px', '128px', '20px')}
          {skeleton('36px', '102px', '20px')}
          {skeleton('36px', '125px', '20px')}
        </div>
      </div>
      <div className='flex justify-center bg-slate-50 h-[230px] border-t xl:-mx-[calc((100vw-theme(screens.xl))/2+theme(spacing.6))]'>
        <div className='px-12 py-6 w-full items-center justify-center max-w-screen-xl'>
          <div className='grid grid-cols-2'>
            <div className='flex w-full gap-x-10 h-full'>
              <div>{skeleton('120px', '120px', '8px')}</div>
              <div className='w-px bg-zinc-200 h-full' />
              <div className='flex flex-col w-full justify-evenly'>
                {new Array(5).fill(0).map((_, i) => (
                  <Fragment key={i}>{skeleton('16px', '40%')}</Fragment>
                ))}
              </div>
            </div>
            <div className='flex self-center'>{skeleton('160px', '100%', '20px')}</div>
          </div>
        </div>
        {/*<div className='px-12 py-6 w-full items-center justify-center max-w-screen-xl'>
          <div className='grid grid-cols-2 gap-x-10'>
            <div className='grid grid-cols-2'>
              <div className='flex flex-col gap-y-6'>
                {skeleton('120px', '120px', '8px')}
                {skeleton('40px', '80%')}
                <div className='flex flex-col gap-y-1'>
                  {skeleton('16px', '50%')}
                  {skeleton('16px', '50%')}
                </div>
                {skeleton('32px', '40%', '20px')}
              </div>
              <div className='flex flex-col gap-y-6'>
                {skeleton('120px', '120px', '8px')}
                {skeleton('40px', '80%')}
                <div className='flex flex-col gap-y-1'>
                  {skeleton('16px', '50%')}
                  {skeleton('16px', '50%')}
                </div>
                {skeleton('32px', '40%', '20px')}
              </div>
            </div>
            <div className='flex items-center justify-end'>{skeleton('160px', '100%', '20px')}</div>
          </div>
        </div>*/}
      </div>
      <div className='flex flex-col gap-y-2 mt-3 px-6'>
        {skeleton('40px', '100%', '6px')}
        {skeleton('200px', '100%', '6px')}
        {skeleton('40px', '100%', '6px')}
        {skeleton('200px', '100%', '6px')}
        {skeleton('40px', '100%', '6px')}
        {skeleton('200px', '100%', '6px')}
      </div>
    </div>
  );
};
