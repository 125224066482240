import { regular } from '@fortawesome/fontawesome-svg-core/import.macro';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import cn from 'classnames';
import { useState } from 'react';
import { Navigate, Route, Routes, useLocation } from 'react-router';
import { NavLink } from 'react-router-dom';
import { Baseline } from './Baseline';
import { Forecasts } from './Forecasts';
import { Historical } from './Historicals';
import { ProgressTracking } from './ProgressTracking';
import { Targets } from './Targets';

const tabs = [
  {
    path: 'progress-tracking/*',
    to: 'progress-tracking',
    name: 'Progress tracking',
    Component: ProgressTracking,
    icon: <FontAwesomeIcon icon={regular('chart-line-down')} />,
  },
  {
    path: 'baseline/*',
    to: 'baseline/production/overview',
    name: 'Baseline',
    Component: Baseline,
    icon: <FontAwesomeIcon icon={regular('flag-checkered')} />,
  },
  {
    path: 'targets/*',
    to: 'targets',
    name: 'Targets',
    Component: Targets,
    icon: <FontAwesomeIcon icon={regular('bullseye-arrow')} />,
  },
  {
    path: 'historical-reports/*',
    to: 'historical-reports',
    name: 'Historical reports',
    Component: Historical,
    icon: <FontAwesomeIcon icon={regular('clock-rotate-left')} />,
  },
  {
    path: 'forecasts/*',
    to: 'forecasts',
    name: 'Forecasts',
    Component: Forecasts,
    icon: <FontAwesomeIcon icon={regular('magnifying-glass-chart')} />,
  },
];

export const Manufacturing = () => {
  const location = useLocation();
  const selectedTab = tabs.find((tab) => location.pathname.includes(`${tab.path}`));
  const [collapsed, setCollapsed] = useState(true);

  return (
    <div className='flex justify-center h-[calc(100vh-theme(spacing.20))] overflow-y-hidden print:overflow-visible'>
      <div className='grid grid-cols-[50px_auto] print:block mx-6 h-full w-full text-sm transition-all duration-300 '>
        <div className='relative z-40 print:hidden'>
          <div
            className={cn(
              'absolute top-0 bottom-0 bg-gradient-to-r from-neutral-200 to-transparent duration-300',
              collapsed ? 'w-0' : 'w-[400px]',
            )}
          />
          <div
            className={cn(
              'z-40 absolute top-0 bottom-0 bg-white transition-[width] duration-300 border-r',
              !collapsed ? 'w-[250px]' : 'w-[50px]',
            )}
          >
            <div className='flex flex-col gap-y-4' onMouseEnter={() => setCollapsed(false)} onMouseLeave={() => setCollapsed(true)}>
              <div
                className={cn(
                  'text-xl h-16 flex items-center overflow-hidden font-semibold transition-opacity',
                  collapsed ? 'opacity-0' : 'opacity-100  border-b border-zinc-200',
                )}
              >
                Volume
              </div>
              <div className='flex flex-col gap-2'>
                {tabs.map((tab, i) => (
                  <NavLink
                    to={tab.to}
                    key={i}
                    className={cn('flex items-center gap-3.5 mr-2', 'hover:bg-slate-100 p-2 rounded-lg', {
                      'bg-slate-200 font-semibold': selectedTab?.path === tab.path,
                    })}
                  >
                    <div
                      className={cn(
                        'h-6 aspect-square flex items-center justify-center rounded',
                        selectedTab?.path === tab.path ? 'bg-white' : 'bg-slate-200',
                      )}
                    >
                      {tab.icon}
                    </div>
                    <div className='overflow-hidden'>
                      <div
                        className={cn(
                          'text-sm text-neutral-700 whitespace-nowrap transition-all',
                          collapsed ? '-translate-x-full opacity-0' : 'opacity-100',
                        )}
                      >
                        {tab.name}
                      </div>
                    </div>
                  </NavLink>
                ))}
              </div>
            </div>
          </div>
        </div>
        <div className='overflow-y-auto bg-white text-base'>
          <Routes>
            {tabs.map(({ path, Component }) => (
              <Route key={path} path={path} element={<Component />} />
            ))}
            <Route path='*' element={<Navigate to='progress-tracking' replace />} />
          </Routes>
        </div>
      </div>
    </div>
  );
};
