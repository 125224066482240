import { duotone, regular, solid } from '@fortawesome/fontawesome-svg-core/import.macro';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import cn from 'classnames';
import { FormikContextType } from 'formik';
import { RefObject } from 'react';
import {
  ConsumptionNode,
  FinalDestinationNode,
  GenericNode,
  IngredientNode,
  IngredientType,
  NodeType,
  PackagingNode,
  ProductionNode,
  ProductionWarehouseNode,
  ProductType,
  ProductV3,
  RawMaterialNode,
  StoreNode,
  Tag,
  WarehouseNode,
} from '../../../../api';
import { MenuItemConfig } from '../../../../components/Menu';
import { ModalFormApi } from '../../../../components/ModalForm';
import { ConsumptionLocationDetails } from './ConsumptionLocationDetails';
import { FinalDestinationDetails } from './FinalDestinationDetails';
import { IngredientDetails } from './IngredientDetails';
import { PackagingDetails } from './PackagingDetails';
import { ProductionFacilityDetails } from './ProductionFacilityDetails';
import { ProductionWarehouseDetails } from './ProductionWarehouseDetails';
import { StoreDetails } from './StoreDetails';
import { TransportDetails } from './TransportDetails';
import { WarehouseDetails } from './WarehouseDetails';
import {
  duplicate,
  getPrimaryTag,
  hasValidationMessage,
  isPlaceholder,
  onAdd,
  onEdit,
  onEditFromMulti,
  onMultiAdd,
  onSaveTransport,
  remove,
} from './dataModel';

export const getBorderStyle = (
  node: GenericNode | undefined,
  formik: FormikContextType<ProductV3>,
  options: { ignoreLessImportantValidation: boolean },
) =>
  cn(
    'border',
    (() => {
      if (node) {
        if (hasValidationMessage(node, formik, { ignoreLessImportant: options.ignoreLessImportantValidation })) {
          return 'border-red-500';
        }

        if (node.flagged) {
          return 'border-[rgba(34,0,102,0.4)]';
        }

        if (isPlaceholder(node)) {
          return 'border-zinc-400 border-dashed';
        }

        return cn(
          getPrimaryTag(node, formik) === Tag.Default ? 'border-[#E8EAF5]' : 'border-transparent',
          'shadow-[0_0_6px_0_rgba(0,0,0,0.1)]',
        );
      }

      return 'border-transparent';
    })(),
  );

const getFlagMenuItem = (data: GenericNode, formik: FormikContextType<ProductV3>) =>
  ({
    icon: data.flagged ? regular('flag') : solid('flag'),
    label: data.flagged ? 'Unflag' : 'Flag',
    onClick: () => formik.setFieldValue(`nodes.${formik.values.nodes.findIndex(({ id }) => id === data.id)}.flagged`, !data.flagged),
  } as MenuItemConfig);

const getExportRawMaterialMenuItem = (data: RawMaterialNode, openModal: (data: RawMaterialNode) => void) =>
  ({
    icon: regular('upload'),
    label: 'Export',
    onClick: () => openModal(data),
  } as MenuItemConfig);

export const getIngredientMenuItems = (
  data: IngredientNode,
  formik: FormikContextType<ProductV3>,
  openExportModal: (data: RawMaterialNode) => void,
  root: string,
): MenuItemConfig[] => [
  ...(data.nodes.every(({ edges }) => edges.length > 0)
    ? []
    : [
        {
          icon: regular('truck'),
          label: 'Add transport link',
          modal: (button, onOpenChange) => (
            <TransportDetails fromId={data.id} onOpenChange={onOpenChange} onSave={(params) => onSaveTransport(params, formik)}>
              {button}
            </TransportDetails>
          ),
        } as MenuItemConfig,
      ]),
  getFlagMenuItem(data, formik),
  getExportRawMaterialMenuItem(data, openExportModal),
  {
    icon: regular('clone'),
    label: 'Duplicate',
    onClick: () => duplicate(data, formik),
  },
  {
    icon: regular('pencil'),
    label: 'Edit',
    modal: (button, onOpenChange) => (
      <IngredientDetails onOpenChange={onOpenChange} data={data} onSave={(params) => onEdit(params, formik)}>
        {button}
      </IngredientDetails>
    ),
  },
  ...(data.ingredient.type !== IngredientType.IntermediateProduct
    ? []
    : [
        {
          icon: regular('arrow-up-right-from-square'),
          label: 'Edit internal product',
          onClick: () => window.open(root, '_blank'),
        },
      ]),
  {
    icon: regular('trash-can'),
    label: 'Delete',
    onClick: () => remove(data, formik),
  },
];

export const getPackagingMenuItems = (
  data: PackagingNode,
  formik: FormikContextType<ProductV3>,
  openExportModal: (data: RawMaterialNode) => void,
): MenuItemConfig[] => [
  ...(data.nodes.every(({ edges }) => edges.length > 0)
    ? []
    : [
        {
          icon: regular('truck'),
          label: 'Add transport link',
          modal: (button, onOpenChange) => (
            <TransportDetails fromId={data.id} onOpenChange={onOpenChange} onSave={(params) => onSaveTransport(params, formik)}>
              {button}
            </TransportDetails>
          ),
        } as MenuItemConfig,
      ]),
  getFlagMenuItem(data, formik),
  getExportRawMaterialMenuItem(data, openExportModal),
  {
    icon: regular('clone'),
    label: 'Duplicate',
    onClick: () => duplicate(data, formik),
  },
  {
    icon: regular('pencil'),
    label: 'Edit',
    modal: (button, onOpenChange) => (
      <PackagingDetails onOpenChange={onOpenChange} data={data} onSave={(params) => onEdit(params, formik)}>
        {button}
      </PackagingDetails>
    ),
  },
  {
    icon: regular('trash-can'),
    label: 'Delete',
    onClick: () => remove(data, formik),
  },
];

export const getProductionMenuItems = (
  data: ProductionNode | ProductionWarehouseNode,
  formik: FormikContextType<ProductV3>,
): MenuItemConfig[] => [
  {
    icon: regular('truck'),
    label: 'Add transport link',
    modal: (button, onOpenChange) => (
      <TransportDetails fromId={data.id} onOpenChange={onOpenChange} onSave={(params) => onSaveTransport(params, formik)}>
        {button}
      </TransportDetails>
    ),
  },
  getFlagMenuItem(data, formik),
  {
    icon: regular('pencil'),
    label: 'Edit',
    modal: (button, onOpenChange) =>
      data.type === NodeType.Production ? (
        <ProductionFacilityDetails onOpenChange={onOpenChange} data={data} onSave={(params) => onEdit(params, formik)}>
          {button}
        </ProductionFacilityDetails>
      ) : (
        <ProductionWarehouseDetails
          onOpenChange={onOpenChange}
          key={data.id}
          data={data}
          onSave={(params) => onEditFromMulti(params, formik)}
        >
          {button}
        </ProductionWarehouseDetails>
      ),
  },
  {
    icon: regular('trash-can'),
    label: 'Delete',
    onClick: () => remove(data, formik),
  },
];

export const getDistributionMenuItems = (
  data: WarehouseNode | StoreNode | FinalDestinationNode,
  formik: FormikContextType<ProductV3>,
): MenuItemConfig[] => [
  ...(data.type === NodeType.FinalDestination
    ? []
    : [
        {
          icon: regular('truck'),
          label: 'Add transport link',
          modal: (button, onOpenChange) => {
            return (
              <TransportDetails fromId={data.id} onOpenChange={onOpenChange} onSave={(params) => onSaveTransport(params, formik)}>
                {button}
              </TransportDetails>
            );
          },
        } as MenuItemConfig,
      ]),
  getFlagMenuItem(data, formik),
  {
    icon: regular('pencil'),
    label: 'Edit',
    modal: (button, onOpenChange) =>
      data.type === NodeType.Warehouse ? (
        <WarehouseDetails onOpenChange={onOpenChange} key={data.id} data={data} onSave={(params) => onEditFromMulti(params, formik)}>
          {button}
        </WarehouseDetails>
      ) : data.type === NodeType.Store ? (
        <StoreDetails onOpenChange={onOpenChange} key={data.id} data={data} onSave={(params) => onEditFromMulti(params, formik)}>
          {button}
        </StoreDetails>
      ) : (
        <FinalDestinationDetails onOpenChange={onOpenChange} key={data.id} data={data} onSave={(params) => onEdit(params, formik)}>
          {button}
        </FinalDestinationDetails>
      ),
  },
  {
    icon: regular('trash-can'),
    label: 'Delete',
    onClick: () => remove(data, formik),
  },
];

export const getConsumptionMenuItems = (data: ConsumptionNode, formik: FormikContextType<ProductV3>): MenuItemConfig[] => [
  getFlagMenuItem(data, formik),
  {
    icon: regular('pencil'),
    label: 'Edit',
    modal: (button, onOpenChange) => (
      <ConsumptionLocationDetails
        onOpenChange={onOpenChange}
        key={data.id}
        data={data}
        onSave={(params) => onEditFromMulti(params, formik)}
      >
        {button}
      </ConsumptionLocationDetails>
    ),
  },
  {
    icon: regular('trash-can'),
    label: 'Delete',
    onClick: () => remove(data, formik),
  },
];

export const renderRawMaterialsAddButtons = (addPackagingModal: RefObject<ModalFormApi>, formik: FormikContextType<ProductV3>) => (
  <>
    <IngredientDetails onSave={(params) => onAdd(params, formik)}>
      <button
        type='button'
        className='group relative flex justify-center items-center gap-1 rounded-lg p-2 text-green-900 bg-green-50 shadow-[0_0_2px_0_rgba(0,0,0,0.2)] active:scale-95'
      >
        <FontAwesomeIcon icon={duotone('carrot')} className='group-hover:invisible' />
        <div className='group-hover:invisible'>Add ingredient</div>
        <FontAwesomeIcon icon={solid('plus')} className='absolute text-base hidden group-hover:block' />
      </button>
    </IngredientDetails>
    <PackagingDetails modalRef={addPackagingModal} onSave={(params) => onAdd(params, formik)}>
      <button
        type='button'
        className='group relative flex justify-center items-center gap-1 rounded-lg p-2 text-lime-900 bg-lime-50 shadow-[0_0_2px_0_rgba(0,0,0,0.2)] active:scale-95'
      >
        <FontAwesomeIcon icon={duotone('box-open')} className='group-hover:invisible' />
        <div className='group-hover:invisible'>Add packaging</div>
        <FontAwesomeIcon icon={solid('plus')} className='absolute text-base hidden group-hover:block' />
      </button>
    </PackagingDetails>
  </>
);

export const renderProductionAddButtons = (formik: FormikContextType<ProductV3>, options?: { shorten?: boolean }) => (
  <>
    <ProductionFacilityDetails onSave={(params) => onAdd(params, formik)}>
      <button
        type='button'
        className='group relative flex shrink-0 justify-center items-center gap-1 rounded-lg p-2 text-rose-900 bg-rose-50 shadow-[0_0_2px_0_rgba(0,0,0,0.2)] active:scale-95'
      >
        {!options?.shorten && <FontAwesomeIcon icon={duotone('industry-windows')} className='group-hover:invisible' />}
        <div className='group-hover:invisible'>{options?.shorten ? 'Facility' : 'Add production facility'}</div>
        <FontAwesomeIcon icon={solid('plus')} className='absolute text-base hidden group-hover:block' />
      </button>
    </ProductionFacilityDetails>
    <ProductionWarehouseDetails onSave={(params) => onMultiAdd(params, formik)}>
      <button
        type='button'
        className='group relative flex shrink-0 justify-center items-center gap-1 rounded-lg p-2 text-fuchsia-900 bg-fuchsia-50 shadow-[0_0_2px_0_rgba(0,0,0,0.2)] active:scale-95'
      >
        {!options?.shorten && <FontAwesomeIcon icon={duotone('warehouse-full')} className='group-hover:invisible' />}
        <div className='group-hover:invisible'>{options?.shorten ? 'Storage' : 'Add storage'}</div>
        <FontAwesomeIcon icon={solid('plus')} className='absolute text-base hidden group-hover:block' />
      </button>
    </ProductionWarehouseDetails>
  </>
);

export const renderDistributionAddButtons = (
  addWarehouseModal: RefObject<ModalFormApi>,
  addStoreModal: RefObject<ModalFormApi>,
  formik: FormikContextType<ProductV3>,
  options?: { shorten?: boolean },
) => (
  <>
    <WarehouseDetails modalRef={addWarehouseModal} onSave={(params) => onMultiAdd(params, formik)}>
      <button
        type='button'
        className='group relative flex shrink-0 justify-center items-center gap-1 rounded-lg p-2 text-fuchsia-900 bg-fuchsia-50 shadow-[0_0_2px_0_rgba(0,0,0,0.2)] active:scale-95'
      >
        {!options?.shorten && <FontAwesomeIcon icon={duotone('warehouse-full')} className='group-hover:invisible' />}
        <div className='group-hover:invisible'>{options?.shorten ? 'Storage' : 'Add storage'}</div>
        <FontAwesomeIcon icon={solid('plus')} className='absolute text-base hidden group-hover:block' />
      </button>
    </WarehouseDetails>
    {formik.values.productType === ProductType.Intermediate ? (
      <FinalDestinationDetails onSave={(params) => onAdd(params, formik)}>
        <button
          type='button'
          className='group relative flex shrink-0 justify-center items-center gap-1 rounded-lg p-2 text-blue-900 bg-blue-50 shadow-[0_0_2px_0_rgba(0,0,0,0.2)] active:scale-95'
        >
          {!options?.shorten && <FontAwesomeIcon icon={duotone('flag-checkered')} className='group-hover:invisible' />}
          <div className='group-hover:invisible'>{options?.shorten ? 'Final Des.' : 'Add final destination'}</div>
          <FontAwesomeIcon icon={solid('plus')} className='absolute text-base hidden group-hover:block' />
        </button>
      </FinalDestinationDetails>
    ) : (
      <StoreDetails modalRef={addStoreModal} onSave={(params) => onMultiAdd(params, formik)}>
        <button
          type='button'
          className='group relative flex shrink-0 justify-center items-center gap-1 rounded-lg p-2 text-blue-900 bg-blue-50 shadow-[0_0_2px_0_rgba(0,0,0,0.2)] active:scale-95'
        >
          {!options?.shorten && <FontAwesomeIcon icon={duotone('store')} className='group-hover:invisible' />}
          <div className='group-hover:invisible'>{options?.shorten ? 'Store' : 'Add store'}</div>
          <FontAwesomeIcon icon={solid('plus')} className='absolute text-base hidden group-hover:block' />
        </button>
      </StoreDetails>
    )}
  </>
);

export const renderUseAddButtons = (formik: FormikContextType<ProductV3>) => (
  <ConsumptionLocationDetails onSave={(params) => onMultiAdd(params, formik)}>
    <button
      type='button'
      className='group relative flex justify-center items-center gap-1 rounded-lg p-2 text-cyan-900 bg-cyan-50 shadow-[0_0_2px_0_rgba(0,0,0,0.2)] active:scale-95'
    >
      <FontAwesomeIcon icon={duotone('house-user')} className='group-hover:invisible' />
      <div className='group-hover:invisible'>Add consumption location</div>
      <FontAwesomeIcon icon={solid('plus')} className='absolute text-base hidden group-hover:block' />
    </button>
  </ConsumptionLocationDetails>
);
