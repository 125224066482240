import cn from 'classnames';
import { Fragment } from 'react';
import { ContextualExample } from '../api';
import { getContextualExampleIcon } from '../icons/ContextualExample';
import { Tooltip } from './Tooltip';

interface Props {
  contextualExample: ContextualExample;
}

export const ContextualExampleTile = (props: Props) => {
  const getColor = () => {
    if (props.contextualExample.changeIsUp === false) {
      return '#5f8e25';
    }
    if (props.contextualExample.changeIsUp) {
      return '#c92e2e';
    }
    return '';
  };

  return (
    <Tooltip
      content={
        <div className='p-2'>
          <div className='max-h-96'>{props.contextualExample.popUp.map(({ text }) => text).join(' ')}</div>
        </div>
      }
    >
      <div className='text-center rounded-2xl bg-white shadow-md print:shadow-none break-inside-avoid'>
        <div className='flex justify-between flex-col h-full'>
          <div
            style={{ backgroundColor: getColor() + '10' }}
            className={cn('flex flex-col justify-center items-center rounded-t-2xl pt-3 border border-b-0', {
              'pb-5': props.contextualExample.changeIsUp !== undefined,
              'border-f': props.contextualExample.changeIsUp === true,
              'border-a': props.contextualExample.changeIsUp === false,
            })}
          >
            <div className='h-auto font-semibold min-h-12 text-dark'>{props.contextualExample.title}</div>
            <div className='flex h-[44px] max-w-[90px] self-center w-40'>{getContextualExampleIcon(props.contextualExample.iconId)}</div>
          </div>
          <div
            style={{ backgroundColor: getColor() + '10' }}
            className={cn('flex justify-start items-start h-full text-neutral-500 rounded-b-2xl', {
              'pl-2 pr-10 border border-t-0': props.contextualExample.changeIsUp !== undefined,
              'border-f': props.contextualExample.changeIsUp === true,
              'border-a': props.contextualExample.changeIsUp === false,
              'border border-t-0 p-3': props.contextualExample.changeIsUp === undefined,
            })}
          >
            {props.contextualExample.changeIsUp !== undefined && (
              <div className={cn('self-center', { 'rotate-180': props.contextualExample.changeIsUp })}>
                <svg width='30' viewBox='0 0 24 38' fill={getColor()}>
                  <path
                    d='M10.9393 37.0607C11.5251 37.6464 12.4749 37.6464 13.0607 37.0607L22.6066 27.5147C23.1924 26.9289 23.1924 25.9792 22.6066 25.3934C22.0208 24.8076 21.0711 24.8076 20.4853 25.3934L12 33.8787L3.51472 25.3934C2.92893 24.8076 1.97919 24.8076 1.3934 25.3934C0.807611 25.9792 0.807611 26.9289 1.3934 27.5147L10.9393 37.0607ZM10.5 0L10.5 36H13.5L13.5 0L10.5 0Z'
                    fill={getColor()}
                  />
                </svg>
              </div>
            )}
            <div
              className={cn('flex flex-col h-full w-full', {
                'pl-2': props.contextualExample.changeIsUp !== undefined,
              })}
            >
              {props.contextualExample.raw.length > 0 && (
                <>
                  <div className='text-brand font-semibold flex justify-center items-center min-h-12'>
                    {props.contextualExample.raw.map(({ text }) => text).join(' ')}
                  </div>
                  <div className='flex items-center justify-center gap-2'>
                    <div className='h-px w-[40px] bg-neutral-400' />
                    <div className={cn('tracking-widest uppercase font-semibold')}>or</div>
                    <div className='h-px w-[40px] bg-neutral-400' />
                  </div>
                </>
              )}
              <div
                className={cn('font-semibold h-full inline-flex justify-center items-center', {
                  'pb-3': props.contextualExample.changeIsUp !== undefined,
                })}
              >
                <span>
                  {props.contextualExample.example.map((item, i) => (
                    <Fragment key={i}>
                      <span className={item.highlight ? 'text-xl text-brand' : 'text-dark'}>{item.text}</span>
                      {i < props.contextualExample.example.length - 1 && <span>&nbsp;</span>}
                    </Fragment>
                  ))}
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Tooltip>
  );
};
