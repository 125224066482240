import { ForwardedRef, forwardRef, PropsWithChildren, useCallback, useContext, useEffect, useRef, useState } from 'react';
import { ModalApi } from '../../../components/ModalV3';
import { getMappingProducts, Mapping, SharedProduct, ProductType } from '../../../api';
import { Modal } from './Modal';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { regular } from '@fortawesome/fontawesome-svg-core/import.macro';
import { useAppRoutes } from '../../../hooks/useAppRoutes';
import { useProfile } from '../../../hooks/useProfile';
import InfiniteScroll from '../../../components/InfiniteScroll';
import { roundToLong, simplify } from '../shared';
import { useDebounce } from '../../../hooks/useDebounce';
import { ProductOverviewModal } from './ProductOverviewModal';
import { WorkspaceContext } from '../../../hooks/useWorspace';

const defaultProductsSearchParams = {
  pageSize: 50,
  sortBy: 'totalImpact',
  sortAscending: 'false',
};

type ProductListModalProps = {
  type: 'mapping';
  initialValues: {
    entity: Mapping | null;
    totalResults: number;
    nextPageToken: string;
    list: SharedProduct[];
  };
};

export const MappingsProductListModal = forwardRef((props: PropsWithChildren<ProductListModalProps>, ref: ForwardedRef<ModalApi>) => {
  const [canFetch, setCanFetch] = useState(false);
  const [modalBodyRef, setModalBodyRef] = useState();

  const [data, setData] = useState<{
    totalResults: number;
    nextPageToken: string;
    list: SharedProduct[];
  }>(props.initialValues);

  const [searchString, setSearchString] = useState('');
  const debouncedSearchString = useDebounce(searchString, 300);

  const getParentReference = useCallback((node) => {
    if (node) {
      setModalBodyRef(node);
    }
  }, []);

  useEffect(() => {
    setData(props.initialValues);
  }, [props.initialValues]);

  const onNext = () =>
    getMappingProducts(props.initialValues.entity!.ingestionMappingId, {
      pageToken: data.nextPageToken,
      ...defaultProductsSearchParams,
    }).ok((data) =>
      setData((current) => ({
        nextPageToken: data.nextPageToken,
        list: [...current.list, ...data.products],
        totalResults: data.totalResults,
      })),
    );

  useEffect(() => {
    if (canFetch) {
      getMappingProducts(props.initialValues.entity!.ingestionMappingId, {
        ...{ contains: debouncedSearchString, ...defaultProductsSearchParams },
      }).ok((data) =>
        setData({
          nextPageToken: data.nextPageToken,
          list: data.products,
          totalResults: data.totalResults,
        }),
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [debouncedSearchString]);

  return (
    <Modal
      ref={ref}
      preventDismiss
      scrollableContainer={getParentReference}
      title={
        props.initialValues.entity && (
          <div className='flex flex-col gap-3'>
            <div className='flex flex-col font-semibold text-xl text-neutral-900'>
              Products using {props.initialValues.entity.extractedData}
            </div>
            <div className='flex justify-between items-center text-base font-normal'>
              <div>Internal ID: {props.initialValues.entity!.internalId}</div>
              <div className='flex gap-x-6'>
                <div className='px-4 py-2 bg-slate-100 rounded-lg'>
                  {data.list.length} Product{data.list.length === 1 ? '' : 's'}
                </div>
                <div className='flex items-center relative'>
                  <input
                    type='search'
                    className='rounded-lg border pl-3 pr-10 py-1.5 placeholder:text-gray-400 border-zinc-500'
                    autoFocus
                    placeholder='Find…'
                    value={searchString}
                    onChange={(event) => setSearchString(event.target.value)}
                  />
                  <FontAwesomeIcon icon={regular('magnifying-glass')} className='absolute right-6 text-light' />
                </div>
              </div>
            </div>
          </div>
        )
      }
      onOpenChange={(open) => {
        if (open) {
          setCanFetch(true);
        } else {
          setCanFetch(false);
          setSearchString('');
          setData({
            totalResults: 0,
            nextPageToken: '',
            list: [],
          });
        }
      }}
      body={
        data.list.length > 0 ? (
          <div className='relative'>
            <div className='font-semibold py-1 bg-white border-b border-zinc-200 sticky top-0 grid grid-cols-[3fr_1fr_1fr_3fr_1fr_1fr] *:py-2'>
              <div className='pl-3'>Name</div>
              <div className='px-2'>Product type</div>
              <div>Net amount</div>
              <div className='px-2'>SKU ID</div>
              <div>Impact Points</div>
              <div></div>
            </div>
            {modalBodyRef && (
              <InfiniteScroll
                next={onNext}
                loader={<></>}
                hasMore={data.nextPageToken !== ''}
                dataLength={data.list.length}
                scrollableTarget={modalBodyRef}
              >
                <div className='grid grid-cols-[3fr_1fr_1fr_3fr_1fr_1fr] *:py-2'>
                  {data.list.map((product) => (
                    <div key={product.id} className='grid grid-cols-subgrid items-center col-span-6 even:bg-slate-50'>
                      <div className='truncate whitespace-nowrap pl-3'>{product.name}</div>
                      <div className='px-2'>
                        {
                          {
                            [ProductType.Final]: 'Final',
                            [ProductType.Intermediate]: 'Intermediate',
                            [ProductType.Internal]: 'Internal',
                          }[product.type]
                        }
                      </div>
                      <div>{product.netAmount}</div>
                      <div className='px-2 truncate'>{product.skuId}</div>
                      <div title={product.impactPoints ? roundToLong(product.impactPoints) : ''} className='font-semibold'>
                        {product.impactPoints ? simplify(product.impactPoints) : ''}
                      </div>
                      <EachModal product={product} />
                    </div>
                  ))}
                </div>
              </InfiniteScroll>
            )}
          </div>
        ) : (
          <div className='py-8 text-center'>No results found</div>
        )
      }
    >
      {props.children}
    </Modal>
  );
});

interface EachModalProps {
  product: SharedProduct;
}

const EachModal = (props: EachModalProps) => {
  const { routes } = useAppRoutes();
  const overviewModalRef = useRef<ModalApi>(null);
  const profile = useProfile();
  const { setWorkspaceId } = useContext(WorkspaceContext);

  return (
    <div className='font-semibold px-3'>
      <ProductOverviewModal ref={overviewModalRef} productId={props.product.id} workspaceId={props.product.workspaceSid} />
      <button
        disabled={!props.product.reportCalculable}
        onClick={async () => {
          if (profile.selectedWorkspace.workspaceSid === props.product.workspaceSid) {
            window.open(routes.products.productOverview.production(props.product.id));
          } else {
            setWorkspaceId(props.product.workspaceSid);
            overviewModalRef.current?.open();
          }
        }}
        className='flex self-center mx-auto bg-slate-200 p-2 rounded-lg disabled:text-zinc-400 hover:text-brand [&:active:not(:disabled)]:scale-95'
      >
        <FontAwesomeIcon className='text-lg' icon={regular('file-chart-column')} />
      </button>
    </div>
  );
};
