import {
  autoUpdate,
  flip,
  offset,
  Placement,
  shift,
  useDismiss,
  useFloating,
  useHover,
  useInteractions,
  useRole,
} from '@floating-ui/react-dom-interactions';
import cn from 'classnames';
import { AnimatePresence, motion } from 'framer-motion';
import { cloneElement, forwardRef, isValidElement, PropsWithChildren, ReactNode, useEffect, useState } from 'react';
import { useMergeRefs } from '@floating-ui/react';

type Props = PropsWithChildren<{
  content: ReactNode;
  placement?: Placement;
  disabled?: boolean;
  offsetMain?: number;
  offsetCross?: number;
  parentControl?: {
    open: boolean;
    onOpenChange: (open: boolean) => void;
  };
}>;

export const TooltipV3 = forwardRef((props: Props, ref) => {
  const [openState, setOpenState] = useState(false);
  const placement = props.placement ?? 'top';
  const disabled = props.disabled;
  const open = props.parentControl?.open ?? openState;

  const { x, y, reference, floating, strategy, context, refs, update } = useFloating({
    placement,
    open,
    onOpenChange: props.parentControl?.onOpenChange ?? setOpenState,
    middleware: [
      offset({ mainAxis: props.offsetMain ?? 10, ...(props.offsetCross ? { crossAxis: props.offsetCross } : {}) }),
      flip(),
      shift(),
    ],
  });

  const { getReferenceProps, getFloatingProps } = useInteractions([
    useHover(context, { delay: { open: 100 }, enabled: !props.disabled && !props.parentControl }),
    useRole(context, { role: 'tooltip' }),
    useDismiss(context, { enabled: !!props.parentControl }),
  ]);

  useEffect(() => {
    if (refs.reference.current && refs.floating.current && open) {
      return autoUpdate(refs.reference.current, refs.floating.current, update);
    }
  }, [refs.reference, refs.floating, update, open]);

  useEffect(() => {
    if (disabled) {
      setOpenState(false);
    }
  }, [disabled]);

  const getAnimationProps = () => {
    switch (placement) {
      case 'left':
        return {
          initial: { opacity: 0, translateX: 4 },
          animate: { opacity: 1, translateX: 0 },
          exit: { opacity: 0, translateX: -4 },
        };
      case 'bottom-start':
      case 'bottom-end':
        return {
          initial: { opacity: 0, translateY: -4 },
          animate: { opacity: 1, translateY: 0 },
          exit: { opacity: 0, translateY: 4 },
        };
      default:
        return {
          initial: { opacity: 0, translateY: 4 },
          animate: { opacity: 1, translateY: 0 },
          exit: { opacity: 0, translateY: -4 },
        };
    }
  };

  const mergedRef = useMergeRefs([ref, reference]);

  return (
    <>
      {isValidElement(props.children) && cloneElement(props.children, getReferenceProps({ ref: mergedRef }))}
      <AnimatePresence>
        {open && (
          <motion.div
            transition={{ type: 'spring', bounce: 0.5, duration: 0.5 }}
            {...getAnimationProps()}
            {...getFloatingProps({
              ref: floating,
              className: cn('antialiased z-50 max-w-xl', {
                'pointer-events-none': !props.parentControl,
              }),
              style: {
                position: strategy,
                top: y ?? '',
                left: x ?? '',
                width: 'max-content',
              },
            })}
          >
            {props.content}
          </motion.div>
        )}
      </AnimatePresence>
    </>
  );
});
