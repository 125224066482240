import { light, regular } from '@fortawesome/fontawesome-svg-core/import.macro';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Field, FieldProps } from 'formik';
import { NavLink } from 'react-router-dom';
import {
  Agreement,
  AgreementType,
  EntityWithSelectedAgreement,
  FacilityWithSelectedAgreement,
  SupplierWithSelectedAgreement,
} from '../../../../api';
import { Popover } from '../../../../components/Popover';
import { TooltipV3 } from '../../../../components/TooltipV3';
import { useLists } from '../../../../hooks/useLists';
import { useAppRoutes } from '../../../../hooks/useAppRoutes';

interface Props {
  for: 'transportSupplier' | 'store' | 'warehouse';
  model: FieldProps<EntityWithSelectedAgreement>;
}

export const AgreementField = (props: Props) => {
  const lists = useLists();
  const { routes } = useAppRoutes();
  return (
    <Field name={`${props.model.field.name}.selectedAgreement`}>
      {(selectedAgreementModel: FieldProps<Agreement>) => {
        const selectedAgreement = selectedAgreementModel.field.value;

        if (!selectedAgreement) {
          return <></>;
        }

        const inheritedAgreement = (
          props.for === 'transportSupplier'
            ? (props.model.field.value as SupplierWithSelectedAgreement).agreement
            : (props.model.field.value as FacilityWithSelectedAgreement).ownershipStatus!.agreement
        )!;

        return (
          <div className='col-span-2 flex flex-col gap-2'>
            <div className='pl-1.5 flex gap-2'>
              <div>Agreement type</div>
              <TooltipV3
                placement='bottom-start'
                content={
                  <div className='bg-[#330099] py-1.5 px-2.5 text-white text-xs w-[380px] rounded-lg'>
                    Specify if you have a formal contractual agreement directly with the{' '}
                    {
                      {
                        transportSupplier: 'supplier',
                        store: 'facility',
                        warehouse: 'facility',
                      }[props.for]
                    }{' '}
                    for the distribution of this specific product through it (‘
                    {lists.agreements.find(({ type }) => type === AgreementType.Direct)!.name}’ option) or if you’re not explicitly paying
                    for the services in question (‘
                    {lists.agreements.find(({ type }) => type === AgreementType.Indirect)!.name}’). This will affect which scope 3
                    sub-category to attribute those emissions to. This is pre-populated with the{' '}
                    {
                      {
                        transportSupplier: 'supplier',
                        store: 'facility',
                        warehouse: 'facility',
                      }[props.for]
                    }
                    ’s default condition.
                  </div>
                }
              >
                <div>
                  <FontAwesomeIcon size='sm' icon={light('info-circle')} />
                </div>
              </TooltipV3>
              {(() => {
                if (selectedAgreement.inherited) {
                  const defaultedObject = (
                    props.for === 'transportSupplier'
                      ? (props.model.field.value as SupplierWithSelectedAgreement).agreement
                      : (props.model.field.value as FacilityWithSelectedAgreement).ownershipStatus
                  )!;
                  return (
                    <Popover
                      placement='bottom-start'
                      content={({ close }) => (
                        <div className='flex flex-col gap-2 bg-brandDarkPurple2 p-4 text-white text-sm w-[380px] rounded-lg'>
                          <div>
                            {defaultedObject.default ? (
                              <>
                                This property, inherited from the{' '}
                                {
                                  {
                                    transportSupplier: 'supplier',
                                    store: 'facility',
                                    warehouse: 'facility',
                                  }[props.for]
                                }
                                , is a default automatically added by us. You may want to change it at the{' '}
                                {
                                  {
                                    transportSupplier: 'supplier',
                                    store: 'facility',
                                    warehouse: 'facility',
                                  }[props.for]
                                }{' '}
                                level:
                              </>
                            ) : (
                              <>
                                This property is inherited from the following{' '}
                                {
                                  {
                                    transportSupplier: 'supplier',
                                    store: 'facility',
                                    warehouse: 'facility',
                                  }[props.for]
                                }
                                :
                              </>
                            )}
                          </div>
                          <NavLink
                            className='self-start flex items-center gap-3 pl-2 pr-3 py-1 rounded bg-white'
                            target='_blank'
                            to={`${routes.root}/manage/${
                              {
                                transportSupplier: 'suppliers/transport',
                                store: 'locations/store',
                                warehouse: 'locations/storage',
                              }[props.for]
                            }/${props.model.field.value.id}`}
                            onClick={close}
                          >
                            <div className='text-zinc-800'>{props.model.field.value.name}</div>
                            <FontAwesomeIcon size='lg' icon={light('arrow-up-right')} className='text-brand' />
                          </NavLink>
                          <div>
                            {defaultedObject.default ? (
                              <>
                                Alternatively, you can change it in here, but this will only apply to this specific{' '}
                                {
                                  {
                                    transportSupplier: 'transport node',
                                    store: 'store and product',
                                    warehouse: 'storage and product',
                                  }[props.for]
                                }
                                .
                              </>
                            ) : (
                              <>
                                If you change it here, the change will only apply to this specific{' '}
                                {
                                  {
                                    transportSupplier: 'transport node',
                                    store: 'store in this product',
                                    warehouse: 'storage in this product',
                                  }[props.for]
                                }{' '}
                                and it will disconnect from the original{' '}
                                {
                                  {
                                    transportSupplier: 'supplier',
                                    store: 'facility',
                                    warehouse: 'facility',
                                  }[props.for]
                                }
                                .
                              </>
                            )}
                          </div>
                        </div>
                      )}
                    >
                      {() => (
                        <button
                          type='button'
                          className='px-2 py-0.5 flex items-center gap-1 text-xs font-semibold text-brand bg-[#E8EAF5] rounded-lg'
                        >
                          {defaultedObject.default && <FontAwesomeIcon icon={regular('exclamation-triangle')} className='text-amber-600' />}
                          <div>Inherited</div>
                        </button>
                      )}
                    </Popover>
                  );
                }

                return (
                  <Popover
                    placement='bottom-start'
                    content={({ close }) => (
                      <div className='flex flex-col gap-3 bg-brandDarkPurple2 p-4 text-white text-sm w-[380px] rounded-lg'>
                        <div>
                          This property is disconnected from the{' '}
                          {
                            {
                              transportSupplier: 'supplier',
                              store: 'facility',
                              warehouse: 'facility',
                            }[props.for]
                          }
                          ’s. Resetting it will inherit the value from:
                        </div>
                        <NavLink
                          className='self-start flex items-center gap-3 pl-2 pr-3 py-1 rounded bg-white'
                          target='_blank'
                          to={`${routes.root}/manage/${
                            {
                              transportSupplier: 'suppliers/transport',
                              store: 'locations/store',
                              warehouse: 'locations/storage',
                            }[props.for]
                          }/${props.model.field.value.id}`}
                          onClick={close}
                        >
                          <div className='text-zinc-800'>{props.model.field.value.name}</div>
                          <FontAwesomeIcon size='lg' icon={light('arrow-up-right')} className='text-brand' />
                        </NavLink>
                        <button
                          type='button'
                          className='self-start mt-1 px-4 py-2 rounded-lg bg-brand active:scale-95'
                          onClick={() => {
                            selectedAgreementModel.form.setFieldValue(selectedAgreementModel.field.name, {
                              ...inheritedAgreement,
                              inherited: true,
                            });
                            close();
                          }}
                        >
                          Reset
                        </button>
                      </div>
                    )}
                  >
                    {() => (
                      <button type='button' className='px-2 py-0.5 text-xs font-semibold text-[#D97706] bg-amber-50 rounded-lg'>
                        Disconnected
                      </button>
                    )}
                  </Popover>
                );
              })()}
            </div>
            <div className='flex flex-col gap-1.5'>
              {lists.agreements.map((agreement) => (
                <label key={agreement.type} className='flex items-center gap-2'>
                  <input
                    type='checkbox'
                    className='m-0.5'
                    checked={agreement.type === selectedAgreementModel.field.value.type}
                    onChange={() =>
                      selectedAgreementModel.form.setFieldValue(selectedAgreementModel.field.name, {
                        ...agreement,
                        inherited: agreement.type === inheritedAgreement.type,
                      })
                    }
                  />
                  <div>{agreement.name}</div>
                </label>
              ))}
            </div>
          </div>
        );
      }}
    </Field>
  );
};
