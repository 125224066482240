import cn from 'classnames';
import { Fragment, useState } from 'react';
import { useProfile } from '../../hooks/useProfile';

enum Tab {
  Details,
  Members,
}

export const Workspace = () => {
  const profile = useProfile();
  const [tab, setTab] = useState(Tab.Details);

  return (
    <div className='flex flex-col gap-6 text-zinc-800 mt-6'>
      <div className='font-semibold text-lg px-6 text-zinc-900'>{profile.selectedWorkspace.name}'s Workspace</div>
      <div className='shrink-0 h-px bg-zinc-300' />
      <div className='flex gap-6 px-6'>
        {[{ label: 'Details', id: Tab.Details }].map(({ label, id }, i) => (
          <Fragment key={id}>
            {i > 0 && <div className='shrink-0 w-px bg-zinc-300' />}
            <button type='button' className='flex flex-col gap-1' onClick={() => setTab(id)}>
              <div className='w-full h-[3px]' />
              <div className='px-1.5 uppercase font-semibold text-xs'>{label}</div>
              <div
                className={cn('bg-brand w-full h-[3px]', {
                  invisible: tab !== id,
                })}
              />
            </button>
          </Fragment>
        ))}
      </div>
      <div className='font-semibold px-6 my-4'>Workspace details</div>
      <div className='grid grid-cols-[max-content_1fr] gap-x-12 gap-y-6 px-6'>
        <div className='text-zinc-600'>Name</div>
        <div className='font-semibold'>{profile.selectedWorkspace.name}</div>
        <div className='text-zinc-600'>Workspace ID</div>
        <div className='font-semibold'>{profile.selectedWorkspace.workspaceSid}</div>
        <div className='text-zinc-600'>Workspace Image</div>
        <div className='flex text-zinc-400'>
          {profile.selectedWorkspace.logoUrl ? (
            <img src={profile.selectedWorkspace.logoUrl} alt='Logo' className='w-6 aspect-square rounded-full' />
          ) : (
            <>Not set</>
          )}
        </div>
        <div className='text-zinc-600'>Organisation</div>
        <div className='flex items-center gap-2'>
          {profile.user.logoUrl && <img src={profile.user.logoUrl} alt='Logo' className='w-6 aspect-square rounded-full' />}
          <div className='font-semibold text-sm'>{profile.user.company}</div>
        </div>
        <div className='text-zinc-600'>Organisation ID</div>
        <div className='font-semibold'>{profile.selectedWorkspace.customerSid}</div>
      </div>
      <div className='shrink-0 h-px bg-zinc-300' />
      <div className='flex flex-col gap-1'>
        <div className='font-semibold px-6'>Delete workspace</div>
        <div className='text-sm px-6'>
          To permanently delete your workspace, please contact our{' '}
          <a href='mailto:impact-help@sustained.com' className='underline'>
            customer support
          </a>
          .
        </div>
      </div>
      <div className='shrink-0 h-px bg-zinc-300' />
    </div>
  );
};
