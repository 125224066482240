import { NotFound } from './NotFound';
import { ErrorPayload } from '../../../api';
import { Forbidden } from './Forbidden';
import { NavLink, useBeforeUnload } from 'react-router-dom';
import { useCallback } from 'react';

interface Props {
  error: ErrorPayload<any>;
  clearError: () => void;
}

export const Errors = (props: Props) => {
  useBeforeUnload(
    useCallback(() => {
      props.clearError();
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []),
  );

  return (
    <>
      {(() => {
        switch (props.error?.responseCode) {
          case 404:
            return <NotFound error={props.error} clearError={props.clearError} />;
          case 403:
            return <Forbidden error={props.error} clearError={props.clearError} />;
          default:
            return (
              <div className='flex flex-col gap-y-6'>
                <div>Unexpected error</div>
                <div>{JSON.stringify(props.error, null, 2)}</div>
                <NavLink to='/'>Home</NavLink>
              </div>
            );
        }
      })()}
    </>
  );
};
