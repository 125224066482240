import { Resources } from '../../../../../../api';
import cn from 'classnames';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { duotone } from '@fortawesome/fontawesome-svg-core/import.macro';
import { simplify, roundToLong } from '../../../../shared';

interface Props {
  data: Resources;
}

interface ResourcePayload {
  iconId: string;
  name: string;
  unit: string;
  value: number;
}

export const ResourceUse = (props: Props) => {
  const electricity = props.data['electricity'];
  const naturalGas = props.data['natural_gas'];
  const water = props.data['water'];
  const waterTreatment = props.data['water_treatment'];

  const renderStage = (item: ResourcePayload) => (
    <div className='flex flex-col gap-1 items-center justify-center'>
      <div className='text-lg print:text-base'>
        <FontAwesomeIcon
          className='h-10 aspect-square'
          icon={
            {
              'circle-bolt': duotone('circle-bolt'),
              'fire-flame-curved': duotone('fire-flame-curved'),
              'inbox-in': duotone('inbox-in'),
              'inbox-out': duotone('inbox-out'),
            }[item.iconId]!
          }
        />
      </div>
      <div className='font-semibold'>{item.name}</div>
      <div title={roundToLong(item.value)} className='text-xl print:text-lg text-brandDark text-center font-semibold min-w-[150px]'>
        {simplify(item.value)} {item.unit}
      </div>
    </div>
  );

  return (
    <>
      {(electricity || naturalGas) && (
        <div className='min-w-[350px] flex flex-col gap-6 bg-white p-6 print:p-3 rounded-2xl print:text-lg border print:shadow-none shadow-md'>
          <div className='flex justify-center'>
            <div className='px-4 py-1 rounded-md bg-brand/10 font-semibold print:text-base text-gray-600 border print:shadow-none shadow-md'>
              Direct Energy Use
            </div>
          </div>
          <div
            className={cn('', {
              'grid grid-cols-[150px_150px] gap-4 justify-center print:grid-cols-[120px_120px]': electricity && naturalGas,
            })}
          >
            {electricity && renderStage(electricity)}
            {naturalGas && renderStage(naturalGas)}
          </div>
        </div>
      )}

      {(water || waterTreatment) && (
        <div className='min-w-[350px] flex flex-col gap-6 bg-white p-6 print:p-3 rounded-2xl print:text-lg border print:shadow-none shadow-md'>
          <div className='flex justify-center'>
            <div className='px-4 py-1 rounded-md bg-brand/10 font-semibold print:text-base text-gray-600 border print:shadow-none shadow-md'>
              Direct Water Use
            </div>
          </div>
          <div
            className={cn('', {
              'grid grid-cols-[150px_150px] gap-4 justify-center print:grid-cols-[120px_120px]': water && waterTreatment,
            })}
          >
            {water && renderStage(water)}
            {waterTreatment && renderStage(waterTreatment)}
          </div>
        </div>
      )}
    </>
  );
};
