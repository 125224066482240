import { Formik, useFormikContext } from 'formik';
import { useEffect, useRef, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import { Navigate, Route, Routes, useParams } from 'react-router';
import { ModellingPayload, ProductModelV3, getProductModelV3, updateProductModelV3 } from '../../../../api';
import { useApiQuery } from '../../../../hooks/useApiQuery';
import { Graph } from './Graph';

export const Modelling = () => {
  const { productId, modelId } = useParams<{ productId: string; modelId: string }>();
  const product = useApiQuery(getProductModelV3(productId!, modelId!));

  if (!product.data) {
    return <></>;
  }

  return (
    <Formik initialValues={product.data.model} onSubmit={() => {}}>
      <Content payload={product.data} update={product.update} />
    </Formik>
  );
};

const Content = (props: { payload: ModellingPayload; update: (payload: ModellingPayload) => void }) => {
  const formik = useFormikContext<ProductModelV3>();
  const ignoreFormikChange = useRef(true);
  const [waiting, setWaiting] = useState(false);
  const [showOriginal, setShowOriginal] = useState(false);

  const undoing = useRef(false);
  const [undoState, setUndoState] = useState({ current: 0, list: [props.payload] });
  const [newUndo, setNewUndo] = useState<ModellingPayload | undefined>();
  const canUndo = undoState.current > 0 && !waiting && !undoing.current;
  const canRedo = undoState.current < undoState.list.length - 1 && !waiting && !undoing.current;

  const updatePayload = (payload: ModellingPayload) => {
    formik.setValues(payload.model);
    props.update(payload);
  };

  const onUndo = () => {
    undoing.current = true;
    updatePayload(undoState.list[undoState.current - 1]);
    setUndoState((old) => ({
      ...old,
      current: old.current - 1,
    }));
  };

  const onRedo = () => {
    undoing.current = true;
    updatePayload(undoState.list[undoState.current + 1]);
    setUndoState((old) => ({
      ...old,
      current: old.current + 1,
    }));
  };

  const onToggleShowOriginal = () => {
    ignoreFormikChange.current = true;

    const model = props.payload.model;

    if (showOriginal) {
      formik.setValues(model);
    } else {
      formik.setValues({ ...props.payload.product, validation: model.validation, title: model.title });
    }

    setShowOriginal((toggle) => !toggle);
  };

  useEffect(() => {
    if (!ignoreFormikChange.current) {
      setWaiting(true);
      updateProductModelV3({ ...props.payload, model: formik.values }).call({
        ok: (updatedPayload) => {
          ignoreFormikChange.current = true;

          const newPayload = { ...updatedPayload, product: props.payload.product };
          setWaiting(false);
          updatePayload(newPayload);

          if (!undoing.current) {
            setNewUndo(newPayload);
          }

          undoing.current = false;
        },
        fail: () => {
          setWaiting(false);
          undoing.current = false;
        },
      });
    }

    ignoreFormikChange.current = false;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formik.values]);

  useEffect(() => {
    if (newUndo) {
      setUndoState((old) => ({
        current: old.current + 1,
        list: [...old.list.slice(0, old.current + 1), newUndo],
      }));
    }
  }, [newUndo]);

  useEffect(() => {
    const onKey = (event: KeyboardEvent) => {
      if (!waiting && event.key === 'z' && (event.ctrlKey || event.metaKey)) {
        event.preventDefault();
        const redo = event.shiftKey;

        if (redo) {
          if (canRedo) {
            onRedo();
          }
        } else {
          if (canUndo) {
            onUndo();
          }
        }
      }
    };

    window.addEventListener('keydown', onKey);

    return () => window.removeEventListener('keydown', onKey);
  });

  return (
    <div className='mt-4 -mb-1 text-neutral-900 text-sm'>
      <Helmet title={formik.values.title} />
      <Routes>
        <Route
          path='graph'
          element={
            <Graph
              payload={props.payload}
              showOriginal={{
                value: showOriginal,
                toggle: onToggleShowOriginal,
              }}
              waiting={waiting}
              canUndo={canUndo}
              canRedo={canRedo}
              onUndo={onUndo}
              onRedo={onRedo}
            />
          }
        />
        <Route path='*' element={<Navigate to='graph' replace />} />
      </Routes>
    </div>
  );
};
