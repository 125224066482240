import { Fragment, useEffect, useReducer, useState } from 'react';

import { Facilities } from './Facilities';
import { Mappings } from './Mappings';
import { Suppliers } from './Suppliers';

import { light, solid } from '@fortawesome/fontawesome-svg-core/import.macro';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import cn from 'classnames';
import { useParams } from 'react-router';
import { useNavigate } from 'react-router-dom';
import {
  Counters,
  Facility,
  FacilityType,
  Mapping,
  Supplier,
  SupplierService,
  Tag,
  arrayToStringParam,
  getFacilities,
  getSuppliers,
  getWorkspaceMappings,
} from '../../../api';
import { useDebounce } from '../../../hooks/useDebounce';
import { useEffectOnNextRenders } from '../../../hooks/useEffectOnNextRenders';
import { useProfile } from '../../../hooks/useProfile';
import { TooltipV3 } from '../../../components/TooltipV3';
import { useAppRoutes } from '../../../hooks/useAppRoutes';

enum Tab {
  Suppliers = 'suppliers',
  Locations = 'locations',
  Mappings = 'mappings',
}

export const resolvedMappings = arrayToStringParam([Tag.ExactMatch, Tag.UserInput]);
export const unresolvedMappings = arrayToStringParam([Tag.BestMatch, Tag.Unmatched]);

const defaultSearchParams = {
  pageSize: 50,
  contains: '',
  pageToken: '',
  sortAscending: true,
};

type DefaultSearchParams = typeof defaultSearchParams;

export interface LocationsSearchParams extends DefaultSearchParams {
  sortBy: 'name';
  location?: string;
  owner?: string;
  type?: FacilityType;
}

export interface SuppliersSearchParams extends DefaultSearchParams {
  sortBy: string;
  service?: SupplierService;
}

export interface MappingsSearchParams extends DefaultSearchParams {
  type?: 'ingredient' | 'packaging' | 'production_step';
  state?: string;
}

export interface ManageEntity<T> {
  list: T;
  totalResults: number;
  nextPageToken: string;
  searchString: string;
  searchParams: T extends Facility[]
    ? LocationsSearchParams
    : T extends Supplier[]
    ? SuppliersSearchParams
    : T extends Mapping[]
    ? MappingsSearchParams
    : never;
  counters?: T extends Mapping[] ? Counters | null : never;
}

export const Overview = () => {
  const navigate = useNavigate();
  const { routes } = useAppRoutes();
  const { category, subcategory } = useParams<{ category: Tab; subcategory: string }>();
  const [selectedCategory, setSelectedCategory] = useState('all');

  const [loading, setLoading] = useState(false);
  const [expanded, setExpanded] = useState<Tab>(category!);

  const [selectedTab, setSelectedTab] = useState<'resolved' | 'unresolved'>('unresolved');

  const { user } = useProfile();

  const [facilityData, setFacilityData] = useState<ManageEntity<Facility[]>>({
    list: [],
    searchString: '',
    searchParams: { ...defaultSearchParams, sortBy: 'name', sortAscending: true },
    nextPageToken: '',
    totalResults: 0,
  });

  const [suppliersData, setSuppliersData] = useState<ManageEntity<Supplier[]>>({
    list: [],
    searchString: '',
    searchParams: { ...defaultSearchParams, sortBy: 'name', sortAscending: true },
    nextPageToken: '',
    totalResults: 0,
  });

  const [mappingsData, setMappingsData] = useState<ManageEntity<Mapping[]>>({
    list: [],
    searchString: '',
    searchParams: { ...defaultSearchParams, state: unresolvedMappings },
    nextPageToken: '',
    counters: null,
    totalResults: 0,
  });

  const debouncedLocationsSearchString = useDebounce(facilityData.searchString, 300);
  const debouncedSuppliersSearchString = useDebounce(suppliersData.searchString, 300);
  const debouncedMappingsSearchString = useDebounce(mappingsData.searchString, 300);

  const [reloadCounter, reload] = useReducer((state) => state + 1, 0);

  const suppliersOptions = [
    { id: SupplierService.BrandOwner, name: 'Brand owners' },
    { id: SupplierService.Manufacturer, name: 'Manufacturers' },
    { id: SupplierService.Ingredient, name: 'Ingredient suppliers' },
    { id: SupplierService.Packaging, name: 'Packaging suppliers' },
    { id: SupplierService.Retailer, name: 'Retailers' },
    { id: SupplierService.Warehouse, name: 'Storage suppliers' },
    { id: SupplierService.Transport, name: 'Transport suppliers' },
  ];

  const locationsOptions = [
    { id: FacilityType.Consumption, name: 'Consumption' },
    { id: FacilityType.Production, name: 'Production' },
    { id: FacilityType.Store, name: 'Stores' },
    { id: FacilityType.Warehouse, name: 'Storage' },
  ];

  useEffect(() => {
    setExpanded(category!);
  }, [category]);

  useEffectOnNextRenders(() => {
    setFacilityData((current) => ({
      ...current,
      searchParams: {
        ...current.searchParams,
        contains: debouncedLocationsSearchString,
      },
    }));
  }, [debouncedLocationsSearchString]);

  useEffectOnNextRenders(() => {
    setSuppliersData((current) => ({
      ...current,
      searchParams: {
        ...current.searchParams,
        contains: debouncedSuppliersSearchString,
      },
    }));
  }, [debouncedSuppliersSearchString]);

  useEffectOnNextRenders(() => {
    setMappingsData((current) => ({
      ...current,
      searchParams: {
        ...current.searchParams,
        contains: debouncedMappingsSearchString,
        state: selectedTab === 'resolved' ? resolvedMappings : unresolvedMappings,
      },
    }));
  }, [debouncedMappingsSearchString]);

  useEffect(() => {
    setSelectedCategory(subcategory ?? 'all');
    setLoading(true);
    if (expanded === Tab.Locations) {
      getFacilities({
        ...facilityData.searchParams,
        type: subcategory !== 'all' ? subcategory : undefined,
        owner: facilityData.searchParams.owner,
        location: facilityData.searchParams.location,
        contains: facilityData.searchParams.contains,
      }).call({
        ok: ({ facilities, nextPageToken, totalResults }) => {
          setFacilityData((current) => ({
            ...current,
            list: facilities,
            nextPageToken,
            totalResults,
          }));

          setLoading(false);
        },
      });
    }

    if (expanded === Tab.Suppliers) {
      setLoading(true);
      getSuppliers({
        ...suppliersData.searchParams,
        contains: suppliersData.searchParams.contains,
        service: subcategory !== 'all' ? subcategory : undefined,
      }).call({
        ok: ({ suppliers, nextPageToken, totalResults }) => {
          setLoading(false);
          setSuppliersData((current) => ({
            ...current,
            list: suppliers,
            nextPageToken,
            totalResults,
          }));
        },
      });
    }

    if (expanded === Tab.Mappings) {
      setLoading(true);
      getWorkspaceMappings({
        ...mappingsData.searchParams,
        type: subcategory !== 'all' ? subcategory : undefined,
      }).call({
        ok: ({ mappings, nextPageToken, counters }) => {
          setLoading(false);
          setMappingsData((current) => ({
            ...current,
            list: mappings,
            nextPageToken,
            counters: counters,
          }));
        },
      });
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [expanded, subcategory, suppliersData.searchParams, facilityData.searchParams, mappingsData.searchParams, reloadCounter]);

  const fetchNextSuppliersPage = () => {
    getSuppliers({
      ...defaultSearchParams,
      sortAscending: suppliersData.searchParams.sortAscending,
      service: subcategory !== 'all' ? subcategory : undefined,
      contains: suppliersData.searchParams.contains,
      pageToken: suppliersData.nextPageToken,
    }).ok(({ suppliers, nextPageToken }) => {
      setSuppliersData((current) => ({
        ...current,
        nextPageToken,
        list: [...current.list, ...suppliers],
      }));
    });
  };

  const fetchNextLocationsPage = () => {
    getFacilities({
      ...defaultSearchParams,
      type: subcategory !== 'all' ? subcategory : undefined,
      owner: facilityData.searchParams.owner,
      location: facilityData.searchParams.location,
      contains: facilityData.searchParams.contains,
      pageToken: facilityData.nextPageToken,
    }).ok(({ facilities, nextPageToken }) => {
      setFacilityData((current) => ({
        ...current,
        nextPageToken,
        list: [...current.list, ...facilities],
      }));
    });
  };

  const fetchNextMappingsPage = () => {
    getWorkspaceMappings({
      ...defaultSearchParams,
      pageToken: mappingsData.nextPageToken,
      state: mappingsData.searchParams.state,
      type: selectedCategory !== 'all' ? selectedCategory : undefined,
    }).ok(({ mappings, nextPageToken }) => {
      setMappingsData((current) => ({
        ...current,
        nextPageToken,
        list: [...current.list, ...mappings],
      }));
    });
  };

  return (
    <div className='flex justify-center h-[calc(100vh-theme(spacing.20))]'>
      <div className='max-w-screen-xl mx-6 h-full w-full grid grid-cols-[250px_auto] text-sm border border-y-0'>
        <div className='h-full w-full border-r'>
          <TooltipV3
            placement='bottom-start'
            offsetMain={-10}
            content={
              <div className='max-w-[250px] px-3'>
                <div className='bg-violet-200 rounded-lg shadow px-2 py-1 text-zinc-800 text-xs font-normal leading-3'>
                  All lists are managed at the organisation level, this means that any edit will be visible by all workspaces.
                </div>
              </div>
            }
          >
            <div className='h-16'>
              <div className='flex m-3 rounded-full p-1.5 border gap-x-3 items-center truncate'>
                <div className='flex items-center justify-center size-8 rounded-full'>
                  {user.logoUrl ? (
                    <img src={user.logoUrl} alt='Workspace logo' />
                  ) : (
                    <FontAwesomeIcon className='text-lg' icon={light('building')} />
                  )}
                </div>
                <div title={user.company} className='truncate font-semibold text-base'>
                  {user.company}
                </div>
              </div>
            </div>
          </TooltipV3>
          <div className='flex flex-col gap-2 px-3'>
            {(() => {
              const renderButtonIcon = (expanded: boolean, icon: 'users' | 'location-dot' | 'right-to-line') => {
                return {
                  icon: (
                    <FontAwesomeIcon
                      className={cn('size-5 rounded-md p-1', expanded && 'bg-zinc-50')}
                      icon={
                        {
                          users: light('user'),
                          'location-dot': light('location-dot'),
                          'right-to-line': light('arrow-right-to-line'),
                        }[icon]
                      }
                    />
                  ),
                };
              };

              return [
                {
                  id: Tab.Suppliers,
                  name: 'Providers & Suppliers',
                  ...renderButtonIcon(expanded === Tab.Suppliers, 'users'),
                },
                {
                  id: Tab.Locations,
                  name: 'Locations & Facilities',
                  ...renderButtonIcon(expanded === Tab.Locations, 'location-dot'),
                },
                {
                  id: Tab.Mappings,
                  name: 'Mappings',
                  ...renderButtonIcon(expanded === Tab.Mappings, 'right-to-line'),
                },
              ].map((tab, i) => (
                <Fragment key={i}>
                  <button
                    onClick={() => {
                      setSelectedCategory('all');
                      setExpanded(tab.id);
                      navigate(`../${tab.id}/all`);
                    }}
                    className={cn(
                      'flex gap-2 items-center py-2 px-2 rounded-md hover:bg-indigo-50',
                      expanded === tab.id && 'bg-indigo-50 font-semibold',
                    )}
                  >
                    {tab.icon}
                    {tab.name}
                  </button>

                  {tab.id === Tab.Suppliers && expanded === Tab.Suppliers && (
                    <div className='flex flex-col gap-1'>
                      {suppliersOptions.map((option, i) => (
                        <button
                          key={i}
                          onClick={() => {
                            setSelectedCategory(`${option.id}`);
                            setExpanded(Tab.Suppliers);
                            navigate(`../${Tab.Suppliers}/${option.id}`);
                          }}
                          className={cn('flex gap-2 items-center justify-between py-2 px-2 rounded-md ml-3 hover:bg-indigo-50', '', {
                            'bg-indigo-50 font-semibold': selectedCategory === `${option.id}`,
                          })}
                        >
                          <div>{option.name}</div>
                          <FontAwesomeIcon
                            className={cn({ hidden: selectedCategory !== `${option.id}` })}
                            size='xs'
                            icon={solid('chevron-right')}
                          />
                        </button>
                      ))}
                    </div>
                  )}

                  {tab.id === Tab.Locations && expanded === Tab.Locations && (
                    <div className='flex flex-col gap-1'>
                      {locationsOptions.map((option, i) => (
                        <button
                          key={i}
                          onClick={() => {
                            setSelectedCategory(`${option.id}`);
                            setExpanded(Tab.Locations);
                            navigate(`../${Tab.Locations}/${option.id}`);
                          }}
                          className={cn('flex gap-2 items-center justify-between py-2 px-2 rounded-md ml-3 hover:bg-indigo-50', {
                            'bg-indigo-50 font-semibold': selectedCategory === `${option.id}`,
                          })}
                        >
                          <div>{option.name}</div>
                          <FontAwesomeIcon
                            className={cn({ hidden: selectedCategory !== `${option.id}` })}
                            size='xs'
                            icon={solid('chevron-right')}
                          />
                        </button>
                      ))}
                    </div>
                  )}

                  {tab.id === Tab.Mappings && expanded === Tab.Mappings && (
                    <div className='flex flex-col gap-1'>
                      {[
                        {
                          id: 'ingredient',
                          name: 'Ingredients',
                        },
                        {
                          id: 'packaging',
                          name: 'Packagings',
                        },
                      ].map((option, i) => (
                        <button
                          key={i}
                          onClick={() => {
                            setSelectedCategory(`${option.id}`);
                            setExpanded(Tab.Mappings);
                            setMappingsData((current) => ({
                              ...current,
                              list: [],
                              nextPageToken: '',
                            }));
                            navigate(routes.manage.mappings.root(option.id));
                          }}
                          className={cn('flex gap-2 items-center justify-between py-2 px-2 rounded-md ml-3 hover:bg-indigo-50', {
                            'bg-indigo-50 font-semibold': selectedCategory === `${option.id}`,
                          })}
                        >
                          <div>{option.name}</div>
                          <FontAwesomeIcon
                            className={cn({ hidden: selectedCategory !== `${option.id}` })}
                            size='xs'
                            icon={solid('chevron-right')}
                          />
                        </button>
                      ))}
                    </div>
                  )}
                </Fragment>
              ));
            })()}
          </div>
        </div>

        {expanded === Tab.Suppliers && (
          <Suppliers
            {...{ selectedCategory, reload, suppliersData, setSuppliersData, fetchNextSuppliersPage, loading }}
            title={
              selectedCategory !== 'all'
                ? suppliersOptions.find(({ id }) => selectedCategory.replaceAll(`${Tab.Suppliers}_`, '') === id)?.name
                : 'Providers & Suppliers'
            }
          />
        )}
        {expanded === Tab.Locations && (
          <Facilities
            {...{ selectedCategory, reload, fetchNextLocationsPage, facilityData, setFacilityData, loading }}
            title={
              selectedCategory !== 'all'
                ? locationsOptions.find(({ id }) => id === selectedCategory.replaceAll(`${Tab.Locations}_`, ''))?.name
                : 'Locations & Facilities'
            }
          />
        )}
        {expanded === Tab.Mappings && (
          <Mappings
            {...{ selectedCategory, reload, fetchNextMappingsPage, mappingsData, setMappingsData, selectedTab, setSelectedTab, loading }}
          />
        )}
      </div>
    </div>
  );
};
