import {
  autoUpdate,
  flip,
  offset,
  Placement,
  shift,
  useDismiss,
  useFloating,
  useHover,
  useInteractions,
  useRole,
} from '@floating-ui/react-dom-interactions';
import { AnimatePresence, motion } from 'framer-motion';
import { cloneElement, isValidElement, PropsWithChildren, ReactNode, useEffect, useState } from 'react';

type Props = PropsWithChildren<{
  content: ReactNode;
  disabled?: boolean;
  delayed?: boolean;
  placement?: Placement;
  manual?: {
    visible: boolean;
  };
  styles?: string;
}>;

export const Tooltip = (props: Props) => {
  const [open, setOpen] = useState(false);
  const visible = props.manual ? props.manual.visible : open;

  const { x, y, reference, floating, strategy, context, refs, update } = useFloating({
    placement: props.placement ?? 'top',
    open: visible,
    onOpenChange: props.manual ? undefined : setOpen,
    middleware: [offset(10), flip(), shift()],
  });

  const { getReferenceProps, getFloatingProps } = useInteractions([
    useHover(context, { enabled: !props.disabled && !props.manual, delay: { open: props.delayed ? 400 : 100 } }),
    useRole(context, { role: 'tooltip' }),
    useDismiss(context, { enabled: !props.manual }),
  ]);

  useEffect(() => {
    if (refs.reference.current && refs.floating.current && visible) {
      return autoUpdate(refs.reference.current, refs.floating.current, update);
    }
  }, [refs.reference, refs.floating, update, visible]);

  return (
    <>
      {isValidElement(props.children) && cloneElement(props.children, getReferenceProps({ ref: reference }))}
      <AnimatePresence>
        {visible && (
          <motion.div
            transition={{ type: 'spring', bounce: 0.5, duration: 0.5 }}
            initial={{ opacity: 0, translateY: 4 }}
            animate={{ opacity: 1, translateY: 0 }}
            exit={{ opacity: 0, translateY: -4 }}
            {...getFloatingProps({
              ref: floating,
              className: `${props.styles ?? ''} ${
                props.content && 'px-4 py-2'
              } antialiased z-50 max-w-xl text-white bg-brandDark text-sm rounded-3xl shadow-xl pointer-events-none`,
              style: {
                position: strategy,
                top: y ?? '',
                left: x ?? '',
                width: 'max-content',
              },
            })}
          >
            {props.content}
          </motion.div>
        )}
      </AnimatePresence>
    </>
  );
};
