import { useRef, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import { NavLink, useNavigate } from 'react-router-dom';
import { NewFacilityForm } from './NewFacilityForm';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { regular } from '@fortawesome/fontawesome-svg-core/import.macro';
import { ModalApi } from '../../../../components/ModalV3';
import cn from 'classnames';

export const CreateFacility = () => {
  const navigate = useNavigate();
  const formRef = useRef<HTMLDivElement>(null);
  const modalRef = useRef<ModalApi>(null);
  const facilityFormSubmitButtonRef = useRef<{ submit: () => void }>(null);
  const [waiting, setWaiting] = useState(false);

  return (
    <div className='flex flex-col gap-6 mt-6'>
      <Helmet title='New Location' />
      <div className='flex items-center gap-4 px-6'>
        <button
          onClick={() => navigate(-1)}
          className='flex items-center justify-center bg-[#E8EAF5] rounded-lg w-8 h-8 hover:bg-white hover:border-2 hover:border-brand'
        >
          <FontAwesomeIcon className='text-xl text-brand' icon={regular('chevron-left')} />
        </button>
        <div className='text-2xl font-semibold'>New Location</div>
      </div>
      <div className='flex justify-center bg-neutral-50 border-y border-zinc-300 -mx-6 xl:-mx-[calc((100vw-theme(screens.xl))/2+theme(spacing.6))]'>
        <div className='flex flex-col gap-4 w-full max-w-screen-xl px-12 pt-10 pb-16'>
          <div className='text-xl font-semibold'>Where the magic happens</div>
          <div>
            A number of locations are involved in the making of your products, from the initial raw materials sourced all the way down the
            supply chain to the consumer. Adding a new location is simple, all we need is a name, and ID (if you use one), a type, who from
            your providers owns it, and an address/country for our transport estimations.
          </div>
          <div className='mt-4'>
            <NewFacilityForm
              formWide
              name=''
              ref={facilityFormSubmitButtonRef}
              formRef={formRef}
              modalRef={modalRef}
              onCancel={() => {}}
              onCreated={() => navigate('../locations/all')}
              waiting={waiting}
              setWaiting={setWaiting}
            />
          </div>
          <div className='text-brand'>All the fields marked * are mandatory.</div>
        </div>
      </div>
      <div className='flex justify-between px-6 mb-12'>
        <NavLink
          to='../locations/all'
          className='text-center border-2 border-transparent bg-[#E8EAF5] text-brandDarkPurple2 rounded-full px-4 py-1 font-semibold active:scale-95'
        >
          Cancel
        </NavLink>
        <button
          type='button'
          disabled={waiting}
          className={cn(
            'text-center border-2 border-brand bg-brand rounded-full px-4 py-1 text-white font-semibold',
            '[&:active:not(:disabled)]:scale-95 disabled:bg-transparent disabled:text-brand',
            { 'cursor-wait': waiting },
          )}
          onClick={() => facilityFormSubmitButtonRef.current?.submit()}
        >
          Create location
        </button>
      </div>
    </div>
  );
};
