import { Route, Routes, useParams } from 'react-router';
import { getModellingReportV3, ModellingReport, ProductType } from '../../../../../api';
import { lifeCycleStagesColorHexSchema, setColor } from '../../../../../components/charts/shared';
import { ModelSkeletonLoader } from './components/ModelSkeletonLoader';
import { Report } from '../index';
import { Helmet } from 'react-helmet-async';
import { NavLink } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { solid } from '@fortawesome/fontawesome-svg-core/import.macro';

import { Summary } from './1_Summary';
import { ProposedChanges } from './2_ProposedChanges';
import { ImpactChangeResults } from './3_ImpactChangeResults';
import { Interpretation } from './4_Interpretation';
import { ConsumerView } from './5_ConsumerView';
import { Appendix } from './6_Appendix';
import { Overview } from './Overview';
import { useAppRoutes } from '../../../../../hooks/useAppRoutes';
import { useEffect, useState } from 'react';

export const Main = () => {
  const { routes } = useAppRoutes();
  const { productId } = useParams<{ productId: string }>();
  const { modelId } = useParams<{ modelId: string }>();
  const [data, setData] = useState<ModellingReport>();
  const [selectedUnit, setSelectedUnit] = useState<'kg' | 'serving' | 'sku'>('sku');

  const bindColors = (payload: ModellingReport) => {
    return {
      ...payload,
      calculations: {
        ...payload.calculations,
        model: {
          ...payload.calculations.model,
          analysis: {
            ...payload.calculations.model.analysis,
            lifecycleStageImpacts: payload.calculations.model.analysis.lifecycleStageImpacts.map((entity) => ({
              ...entity,
              bgColor: setColor({ key: entity.name }, lifeCycleStagesColorHexSchema),
            })),
            impactStagesMatrix: payload.calculations.model.analysis.impactStagesMatrix.map((it) => ({
              ...it,
              stages: it.stages.map((stage) => ({ ...stage, bgColor: setColor({ key: stage.name }, lifeCycleStagesColorHexSchema) })),
            })),
          },
        },
        product: {
          ...payload.calculations.product,
          analysis: {
            ...payload.calculations.product.analysis,
            lifecycleStageImpacts: payload.calculations.product.analysis.lifecycleStageImpacts.map((entity) => ({
              ...entity,
              bgColor: setColor({ key: entity.name }, lifeCycleStagesColorHexSchema),
            })),
            impactStagesMatrix: payload.calculations.product.analysis.impactStagesMatrix.map((it) => ({
              ...it,
              stages: it.stages.map((stage) => ({ ...stage, bgColor: setColor({ key: stage.name }, lifeCycleStagesColorHexSchema) })),
            })),
          },
        },
      },
    };
  };

  const fetchDataWithSelectedUnit = async (unit: 'kg' | 'serving' | 'sku') => {
    getModellingReportV3(productId!, modelId!, unit).call({
      ok: (data) => {
        setData(bindColors(data));
      },
    });
  };

  useEffect(() => {
    (async () => {
      await fetchDataWithSelectedUnit(selectedUnit);
    })();

    return () => {
      setData(undefined);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedUnit]);

  if (!data) {
    return <ModelSkeletonLoader />;
  }

  const tabs = [
    { path: 'summary', Component: Summary, label: 'Summary' },
    { path: 'proposed-changes', Component: ProposedChanges, label: 'Proposed Changes' },
    { path: 'impact-change-results', Component: ImpactChangeResults, label: 'Impact Change Results' },
    { path: 'interpretation', Component: Interpretation, label: 'Interpretation' },
    { path: 'consumer-view', Component: ConsumerView, label: 'Consumer View' },
    { path: 'appendix', Component: Appendix, label: 'Appendix' },
  ];

  const title = data.model.title;

  const header = (
    <div className='flex items-center justify-between gap-2 h-20 border-b border-zinc-200 bg-white px-3 mx-3'>
      <div className='flex items-center gap-3 text-lg font-semibold truncate'>
        <NavLink className='hover:underline' to={routes.products.list}>
          Products
        </NavLink>
        <FontAwesomeIcon size='xs' icon={solid('chevron-right')} />
        <div className='truncate'>{title}</div>
      </div>
      <NavLink
        to={routes.products.modelOverview(data.product.id, data.model.id)}
        className='whitespace-nowrap text-base font-semibold shadow-md active:scale-95 border-2 border-brandDark text-brandDark px-3 py-1.5 rounded-full'
      >
        Return to overview
      </NavLink>
    </div>
  );

  return (
    <Routes>
      <Route path='overview' element={<Overview selectedUnit={selectedUnit} setSelectedUnit={setSelectedUnit} data={data} />} />
      <Route
        path='*'
        element={
          <Report
            tabs={tabs.filter((tab) => !(tab.path === 'consumer-view' && data.product.productType !== ProductType.Final))}
            data={data}
          >
            <Helmet title={title} />
            {header}
            <div className='m-6 text-dark'>
              This report presents the outcome of the modelling done on the {data.product.name} ({data.product.amount.value}
              {data.product.amount.unit.name}) product. It presents the modelled changes and how these changes could affect the
              environmental impact of the product if implemented.
            </div>
          </Report>
        }
      />
    </Routes>
  );
};
