import { Workspace, request } from '.';

export interface Account {
  user: {
    name: string;
    company: string;
    logoUrl?: string;
  };
  workspaces: ({ isDefault: boolean } & Workspace)[];
}

export const getAccount = () => request<Account>('GET', '/v3/account');

export const signup = (payload: {
  firstName: string;
  lastName: string;
  email: string;
  password: string;
  company: string;
  job: string;
  industry: string;
}) =>
  request<{ errorCode?: 'user-exists' }>('POST', '/v3/signup', {
    body: payload,
  });

export const resetPassword = (payload: { email: string }) =>
  request('POST', '/v3/reset-login', {
    body: payload,
  });

export const updatePasswordFromLink = (payload: { email: string; newPassword: string; code: string }) =>
  request<{ errorCode?: 'expired' }>('POST', '/v3/confirm-password', {
    body: payload,
  });

export const updatePassword = (payload: { newPassword: string }) =>
  request('POST', '/v3/account/change-password', {
    body: payload,
  });
