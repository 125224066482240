import {
  AgreementType,
  Amount,
  Entity,
  Facility,
  Grade,
  Impact,
  ImpactDelta,
  ImpactStageMatrix,
  Interpretation,
  LifecycleStageImpact,
  ModellingChange,
  MultipleProductsFacility,
  request,
  Supplier,
} from '.';
import { PrimaryNode, ProductV3, Size, ValidationItem } from './productV3';

export enum LifeCycleStageName {
  RawMaterials = 'Raw Materials',
  Production = 'Production',
  Distribution = 'Distribution',
  Use = 'Use',
  EndOfLife = 'End Of Life',
}

export enum Lens {
  Production = 'production',
  Consumer = 'consumer',
  Ghg = 'ghg',
}

export enum WarningType {
  DraftProductPlaceholders = 'draft-product-placeholders', // Draft product with placeholders only
  DraftProductDraftInternal = 'draft-product-draft-internal', // Draft product with draft internal product only
  DraftProductPlaceholdersDraftInternal = 'draft-product-placeholders-draft-internal', // Draft product with both
  CompleteProductDraftInternal = 'complete-product-draft-internal', // Complete product with draft internal product
  CompleteProductScreeningLca = 'complete-product-screening-lca', // Complete product with <100% first party data
  DraftProduct = 'draft-product', // Draft product
  ScreeningLcaNotMeet = 'screening-lca-not-meet', // warning for baseline if not enough first-party data
}

interface ResourcePayload {
  iconId: string;
  name: string;
  unit: string;
  value: number;
}

export interface Resources {
  electricity: ResourcePayload;
  natural_gas: ResourcePayload;
  water: ResourcePayload;
  water_treatment: ResourcePayload;
}

interface StageResourceUse extends Entity {
  resources: Resources;
}

export interface MajorProcess extends Entity {
  sharePercent: number;
}

interface Analysis {
  impactStagesMatrix: ImpactStageMatrix[];
  lifecycleStageImpacts: LifecycleStageImpact[];
  majorProcesses: MajorProcess[];
  stageResourceUse: StageResourceUse[];
}

export interface CalculatedEntity {
  analysis: Analysis;
  consumerView: ConsumerView;
  impacts: Impact[];
  overallImpact: number;
  appleIndex: number;
  impactPoints: number;
}

export interface Calculations {
  product: CalculatedEntity;
  model: CalculatedEntity;
  overallChange: {
    value: number;
    changeIsUp: boolean;
  };
  proposedChanges: number;
}

export interface Model {
  id: string;
  title: string;
  author: string;
  createdAt: string;
  updatedAt: string;
  impactDelta: ImpactDelta;
  layoutGrids: {
    production: Size;
    distribution: Size;
  };
  nodes: PrimaryNode[];
  proposedChanges: number;
  validation: {
    errors: ValidationItem[];
    warnings: ValidationItem[];
  };
}

export interface MethodFactors {
  version: string;
  factors: {
    name: string;
    normalisationFactor: number;
    normalisationUnit: string;
    weightingPercent: number;
  }[];
}

export interface ReportMetaData {
  author: string;
  reportDate: string;
  methodologyVersion: string;
  webAppVersion: string;
  functionalUnit: 'sku' | 'kg';
  methodFactors: MethodFactors;
}

export interface ModellingReport {
  calculations: Calculations;
  changes: ModellingChange[];
  model: Model;
  product: ProductV3;
  interpretation: Interpretation;
  proposedChanges: {
    text: string;
    svgIconUrl: string;
    iconId: string;
  }[];
  productLocations: Locations;
  modelLocations: Locations;
  metadata: ReportMetaData;
  scope: {
    scaledProductAmount: Amount;
    scaledServings: number;
  };
}

interface Assumption {
  name: string;
  comment: string;
  values: {
    type: 'string' | 'link' | 'array';
    value?: string;
    link?: string;
    valueArray?: string[];
  }[];
}

interface AssumptionsTableSection {
  name: string;
  comment: string;
  assumptions: Assumption[];
}

interface AssumptionsTable {
  id: string;
  columns: string[];
  sections: AssumptionsTableSection[];
  comments: {
    text: string;
  }[];
}

export interface ConsumerViewImpact {
  id: string;
  name: string;
  grade: Grade;
  svgIconUrl: string;
  iconId: string;
}

interface ConsumerView {
  overallGrade: Grade;
  impacts: ConsumerViewImpact[];
}

interface ProductImprovementTip {
  text: string;
  iconId: string;
  comments: string[];
}

interface Locations {
  production: Entity[];
  sale: Entity[];
}

interface ReportWarningType {
  id: WarningType;
}

export interface ProductReport {
  analysis: Analysis;
  firstPartyDataPercentage: number;
  impactPoints: number;
  assumptionsTables: AssumptionsTable[];
  consumerView: ConsumerView;
  improvementTips: ProductImprovementTip[];
  interpretation: Interpretation;
  metadata: ReportMetaData;
  overallImpact: number;
  product: ProductV3;
  locations: Locations;
  warnings: ReportWarningType[];
}

export type GhgCategory = GhgValues & Entity & { isOutOfScope: boolean };

export interface GasEmission {
  id: string;
  name: string;
  totalEmission: ValueWithUnit;
}

export interface GhgProductReport {
  analysis: SingleProductGhgAnalysis;
  firstPartyDataPercentage: number;
  metadata: ReportMetaData;
  product: ProductV3;
  locations: Locations;
  warnings: ReportWarningType[];
}

export interface MultipleProductsTransportSupplier {
  productIds: string[];
  productsCount: number;
  supplier: Supplier;
}

interface ValueWithUnit {
  value: number;
  unit: string;
}

export interface GhgValues {
  totalBiogenic: ValueWithUnit;
  totalEmission: ValueWithUnit;
}

export interface GhgAnalysis {
  avoidedEmissions: GhgValues;
  categories: GhgCategory[];
  gasEmissions: GasEmission[];
  //totalBiogenic: ValueWithUnit;
  totalEmission: ValueWithUnit;
  scopeEmissions: ({
    totalEmission: ValueWithUnit & { sharePercent: number };
    totalBiogenic: ValueWithUnit;
  } & Entity)[];
}

interface SingleProductSupplier {
  supplier: Supplier;
  category: string;
  overrides: {
    from: string;
    to: string;
    agreement: {
      type: AgreementType;
      name: string;
      default: boolean;
    };
    category: string;
  }[];
}

export interface SingleProductGhgAnalysis extends GhgAnalysis {
  facilities: ({ category: string } & Facility)[];
  transportSuppliers: SingleProductSupplier[];
}

export interface MultipleProductsGhgAnalysis extends GhgAnalysis {
  facilities: MultipleProductsFacility[];
  transportSuppliers: MultipleProductsTransportSupplier[];
}

export const getProductReport = (
  productId: string,
  params?: { search: { lens: Lens; scaleToAmount?: number; scaleUnit?: string }; workspaceSid?: string | null },
) =>
  request<ProductReport>('GET', `/v3/products/${productId}/report`, {
    search: params?.search,
    workspaceSid: params?.workspaceSid,
  });

export const getGhgProductReport = (
  productId: string,
  params?: { search: { scaleToAmount?: number; scaleUnit?: string }; workspaceSid: string },
) =>
  request<GhgProductReport>('GET', `/v3/products/${productId}/report`, {
    search: { lens: Lens.Ghg, search: params?.search },
    workspaceSid: params?.workspaceSid,
  });

export const getProductReportCsv = (payload: { productId: string; fileName: string; lens?: Lens }, workspaceSid?: string) =>
  request('GET', `/v3/products/${payload.productId}/report?lens=${payload.lens ?? Lens.Production}`, {
    csv: payload.fileName,
    workspaceSid: workspaceSid ?? undefined,
  });

export const getProductGhgProtocolCsv = (payload: { productId: string }, workspaceSid?: string) =>
  request('GET', `/v3/products/${payload.productId}/report/ghg-emissions`, { csv: 'ghg-protocol', workspaceSid });

export const getBaselineGhgCsv = (payload: { fileName: string }, workspaceSid?: string) =>
  request('GET', '/v3/manufacturing-baseline/report/ghg-emissions', { csv: payload.fileName, workspaceSid });

export const getHistoricalGhgCsv = (payload: { reportId: string; fileName: string }, workspaceSid?: string) =>
  request('GET', `/v3/manufacturing-history/${payload.reportId}/ghg-emissions`, { csv: payload.fileName, workspaceSid });
