import { request } from './index';

export interface ProductRevisionPreview {
  author: string;
  comment: string;
  createdAt: string;
  id: string;
  revision: number;
}

export const getProductUploadUrlsV3 = (productId: string, fileName: string) =>
  request<{ fileUrl: string; uploadUrl: string }>('POST', `/v3/products/${productId}/upload`, { body: { fileName } });
