import { regular, solid } from '@fortawesome/fontawesome-svg-core/import.macro';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { NavLink } from 'react-router-dom';
import { Menu } from '../../../../components/Menu';
import { ModalV3 } from '../../../../components/ModalV3';
import { BadgesHelpModal } from './BadgesHelp';
import { useAppRoutes } from '../../../../hooks/useAppRoutes';

export const MainInstructions = () => {
  const { routes } = useAppRoutes();

  return (
    <Menu
      zIndex={20}
      placement='bottom-start'
      items={[
        {
          icon: regular('flag-checkered'),
          label: 'Get started',
          modal: (button, onOpenChange) => (
            <ModalV3
              size='wide+'
              title='Start mapping your product life cycle!'
              body={
                <div className='flex flex-col gap-3 -mt-6'>
                  <div>
                    Before you start mapping your product’s life cycle, make sure you’ve created all the{' '}
                    <NavLink className='underline hover:text-brand' to={routes.manage.facilities.root('all')}>
                      facilities and locations
                    </NavLink>{' '}
                    your product goes through as well as the various{' '}
                    <NavLink className='underline hover:text-brand' to={routes.manage.suppliers.root('all')}>
                      providers and suppliers
                    </NavLink>{' '}
                    you work with.
                  </div>
                  <div>Where should you start? Feel free to play around with it yourself, or follow our little step-by-step guide:</div>
                  <ol className='flex flex-col gap-3 list-none font-altform'>
                    <li>
                      <div className='flex gap-1 font-semibold'>
                        <span>1.</span>
                        Start by adding all the facilities/locations in your graph:
                      </div>
                      <ul className='list-disc ml-10 mt-1 flex flex-col gap-1'>
                        <li>
                          The production facility (or facilities) involved in making the product. Make sure you add at least one and specify
                          which production facility is the ‘final’ one, the one resulting in your ready for distribution. You could have
                          many of course, that works too.
                        </li>
                        <li>
                          The various storage facilities where your product gets stored along the way (yours, the logistics companies’ or
                          the retailers’)
                        </li>
                        <li>
                          The stores it gets sold into (you can create a generic regional ‘store’ as a placeholder or add them all
                          individually for more accuracy)
                        </li>
                        <li>
                          The consumption location(s), or the country/region/city/neighbourhood where the product gets consumed by the
                          consumers. It’s up to you how granular you want to get here and how many you want to assess for the same.
                        </li>
                      </ul>
                    </li>
                    <li className='flex gap-1'>
                      <div className='font-semibold'>2.</div>
                      <div>
                        <span className='font-semibold'>Move elements around the grid as needed to create a coherent picture: </span>
                        click the little grid icon to resize your grid, add columns or rows, and move the elements around as you wish to
                        visualise as clearly as possible the flow of your product across its life cycle.
                      </div>
                    </li>
                    <li>
                      <div className='flex gap-1 font-semibold'>
                        <div>3.</div>
                        Add all the raw materials you source to make your final product (make sure your{' '}
                        <NavLink className='underline hover:text-brand' to={routes.manage.suppliers.root('all')}>
                          suppliers have been created
                        </NavLink>
                        first!):
                      </div>
                      <ul className='list-disc ml-10 mt-1 flex flex-col gap-1'>
                        <li>
                          Add as many ingredients and packaging as are required specifying whether you source them from different suppliers
                          throughout the year, and when it comes to packaging, what the composition is.
                        </li>
                        <li>
                          If an ingredient or packaging you’re looking for is missing,{' '}
                          <a
                            className='underline hover:text-brand'
                            href='mailto:impact@sustained.com?subject=Sustained Impact: Ingredient/Packaging missing'
                          >
                            let us know
                          </a>{' '}
                          so we can get our experts working on adding it into the system.
                        </li>
                      </ul>
                    </li>
                    <li className='flex gap-1'>
                      <div className='font-semibold'>4.</div>
                      <div>
                        <span className='font-semibold'>Connect your raw materials to the relevant production facility: </span>
                        Only goods transported to the facility will be available to select as inputs in your production steps.
                      </div>
                    </li>
                    <li className='flex gap-1'>
                      <div className='font-semibold'>5.</div>
                      <div>
                        <span className='font-semibold'>Specify what happens inside the production facilities:</span>
                        <ul className='list-disc ml-10 mt-1 flex flex-col gap-1'>
                          <li>
                            Clicking on the double ended arrow will open any production facility. This is where you can specify the various
                            production steps happening inside
                          </li>
                          <li>Make sure you always specify which one is the final step in any given facility.</li>
                          <li>
                            If you have multiple facilities in sequence, make sure to add a transport link from one to another specifying
                            which output is being transported otherwise you won’t be able to select it as an input in the following
                            facility.
                          </li>
                        </ul>
                      </div>
                    </li>
                    <li className='flex gap-1'>
                      <div className='font-semibold'>6.</div>
                      <div>
                        <span className='font-semibold'>Add the remaining transport links: </span>
                        now that we have a clear view of how your product is made, you can further specify its distribution path(s).
                        Remember that every component needs to be connected to something.
                      </div>
                    </li>
                    <li className='flex gap-1'>
                      <div className='font-semibold'>7.</div>
                      <div>
                        <span className='font-semibold'>Tell us about any preparation required by the consumer: </span>
                        open a given consumption location by clicking on the double ended arrows and add the various steps the consumer has
                        to take to turn the product into something ready to be consumed. Make sure to specify the final step!
                      </div>
                    </li>
                    <li className='flex gap-1'>
                      <div className='font-semibold'>8.</div>
                      <div>
                        <span className='font-semibold'>
                          If you know for a fact that your packaging is non-recyclable in a given geography,{' '}
                        </span>
                        edit the end of life stage of your packaging to let us know.
                      </div>
                    </li>
                  </ol>

                  <div>
                    When you’re happy with how the life cycle looks, click on “Generate report” to discover your product life cycle impact
                    in detail.
                  </div>
                </div>
              }
              confirmLabel='Close'
              hideCancel
              onOpenChange={onOpenChange}
            >
              {button}
            </ModalV3>
          ),
        },
        {
          icon: solid('rectangle-wide'),
          label: 'Input description badges',
          modal: (button, onOpenChange) => <BadgesHelpModal onOpenChange={onOpenChange}>{button}</BadgesHelpModal>,
        },
      ]}
    >
      {renderButton}
    </Menu>
  );
};

export const ProductionFacilityInstructions = () => (
  <ModalV3
    title='Time to map your production processes!'
    body={
      <div className='flex flex-col gap-3 -mt-6'>
        <div>
          This is where you visualise what happens inside your production facility. From factory entry to exit, you should add all the
          processes occurring to create the final product, specify the inputs and the outputs up until the ‘final production step’ of this
          facility, or the last step before its final output.
        </div>
        <div>
          This last step could be the one resulting in the final product creation or an intermediate output which gets sent to another
          facility for further processing.
        </div>
        <div>
          You can move things around in the same way you did the main graph: click the little grid icon to resize your grid, add columns or
          rows, and move the elements around as you wish to visualise as clearly as possible the flow of your production steps in this
          facility.
        </div>
      </div>
    }
    confirmLabel='Close'
    hideCancel
  >
    {renderButton()}
  </ModalV3>
);

export const ConsumptionLocationInstructions = () => (
  <ModalV3
    title='Time for some cooking instructions!'
    body={
      <div className='flex flex-col gap-3 -mt-6'>
        <div>
          What are the various steps a consumer needs to take to be able to eat/drink your product? Let’s take pasta for example, you’ve
          always got to boil it before you can eat it, that's what we're talking about here.
        </div>
        <div>
          Make sure you specify which one is the last step of the process, and connect the various steps with the inputs and outputs. You
          can move things around in the same way you did the main graph: click the little grid icon to resize your grid, add columns or
          rows, and move the elements around as you wish to visualise as clearly as possible the flow of the preparation steps happening
          within the consumer’s home.
        </div>
      </div>
    }
    confirmLabel='Close'
    hideCancel
  >
    {renderButton()}
  </ModalV3>
);

const renderButton = () => (
  <button
    type='button'
    className='flex items-center gap-3 border-2 border-[#220066] rounded-full pl-2 pr-4 py-1 text-[#220066] font-semibold active:scale-95'
  >
    <FontAwesomeIcon icon={regular('question-circle')} className='text-lg' />
    Instructions
  </button>
);
