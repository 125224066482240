import { useProfile } from './useProfile';
import { Lens } from '../api';

export const useAppRoutes = () => {
  const profile = useProfile();
  const root = `/workspaces/${profile.selectedWorkspace?.workspaceSid}`;

  const routes = {
    root,
    workspace: `${root}/workspace`,
    dashboard: `${root}/dashboard`,
    methodology: `${root}/methodology`,
    faq: `${root}/faq`,
    help: `${root}/help`,
    customers: {
      root: `${root}/customers`,
      newCustomer: `${root}/customers/new-customer`,
      editCustomer: (customerId: string) => `${root}/customers/${customerId}`,
    },
    products: (() => ({
      list: `${root}/products`,
      compare: `${root}/products/compare`,
      productGraph: (productId: string, baseUrl?: string) => `${baseUrl ?? root}/products/${productId}`,
      modelGraph: (productId: string, modelId: string, baseUrl?: string) =>
        `${baseUrl ?? root}/products/${productId}/models/${modelId}/graph`,
      productOverview: {
        production: (productId: string, baseUrl?: string) => `${baseUrl ?? root}/products/${productId}/report/production/overview`,
        consumer: (productId: string, baseUrl?: string) => `${baseUrl ?? root}/products/${productId}/report/consumer/overview`,
        ghg: (productId: string, baseUrl?: string) => `${baseUrl ?? root}/products/${productId}/report/ghg/overview`,
      },
      productReport: (productId: string, lens: Lens) => `${root}/products/${productId}/report/${lens}/summary`,
      modelOverview: (productId: string, modelId: string, baseUrl?: string) =>
        `${baseUrl ?? root}/products/${productId}/models/${modelId}/report/overview`,
      revisions: (() => ({
        overview: (productId: string, revisionNumber: number, baseUrl?: string) =>
          `${baseUrl ?? root}/products/${productId}/revisions/${revisionNumber}/overview`,
      }))(),
    }))(),
    manage: (() => ({
      suppliers: {
        root: (tab: string) => `${root}/manage/suppliers/${tab}`,
        details: (categoryId: string, supplierId: string) => `${root}/manage/suppliers/${categoryId}/${supplierId}`,
      },
      facilities: {
        root: (tab: string) => `${root}/manage/locations/${tab}`,
        details: (categoryId: string, facilityId: string) => `${root}/manage/locations/${categoryId}/${facilityId}`,
      },
      mappings: (() => ({
        root: (tab: string) => `${root}/manage/mappings/${tab}`,
        details: (categoryId: string, mappingId: string) => `${root}/manage/mappings/${categoryId}/${mappingId}`,
      }))(),
    }))(),
    manufacturing: (() => ({
      targets: `${root}/manufacturing/targets`,
      baseline: (() => ({
        root: `${root}/manufacturing/baseline`,
        new: `${root}/manufacturing/baseline/new`,
        overview: `${root}/manufacturing/baseline/production/overview`,
      }))(),
      historical: (() => ({
        list: `${root}/manufacturing/historical-reports`,
        new: `${root}/manufacturing/historical-reports/new`,
        overview: (id: string) => `${root}/manufacturing/historical-reports/${id}/report/production/overview`,
      }))(),
      forecast: (() => ({
        list: `${root}/manufacturing/forecasts`,
        new: `${root}/manufacturing/forecasts/new`,
        overview: (id: string) => `${root}/manufacturing/forecasts/${id}/report/production/overview`,
      }))(),
    }))(),
  };

  return { routes };
};
