import { useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Formik } from 'formik';
import { ManufacturingSkeletonLoader } from '../../components/ManufacturingSkeletonLoader';
import { StickyHeader } from '../../../../../components/StickyHeader';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { regular } from '@fortawesome/fontawesome-svg-core/import.macro';
import { createManufacturingBaseline, ManufacturingProduct } from '../../../../../api';
import { validationSchema } from '../../validationSchema';
import { format } from 'date-fns';

import { Step1 } from './Steps/1_Step';
import { Step2 } from './Steps/2_Step';

import { Step3 as ManualStep3 } from './Steps/Manual/3_Step';
import { Step4 as ManualStep4 } from './Steps/Manual/4_Step';

import { Step3 as ImportStep3 } from './Steps/Import/3_Step';
import { ManufacturingReportCreationMethod } from '../../types';
import { useAppRoutes } from '../../../../../hooks/useAppRoutes';

export const NewBaseline = () => {
  const reportTitle = `Baseline ${format(new Date(), 'yyyy/MM/dd')}`;
  const [selectedMethod, setSelectedMethod] = useState<ManufacturingReportCreationMethod>();

  const [startDate, setStartDate] = useState<Date | null>(null);
  const [endDate, setEndDate] = useState<Date | null>(null);

  const [step, setStep] = useState<number>(1);

  const [waiting, setWaiting] = useState<boolean>(false);
  const [triedSubmitting, setTriedSubmitting] = useState(false);
  const parentRef = useRef<HTMLDivElement>(null);

  const navigate = useNavigate();
  const { routes } = useAppRoutes();

  return (
    <div ref={parentRef} className='flex flex-col gap-y-6'>
      {!waiting ? (
        <>
          <Formik<{ products: ManufacturingProduct[] }>
            initialValues={{ products: [] }}
            validationSchema={validationSchema}
            validateOnBlur={triedSubmitting}
            validateOnChange={triedSubmitting}
            onSubmit={(values) => {
              setWaiting(true);
              const payload = {
                products: [...values.products.filter(({ selected, id }) => selected).map(({ id, count }) => ({ id, count }))],
                startDate: new Date(startDate!.setMinutes(-startDate!.getTimezoneOffset())),
              };

              createManufacturingBaseline(payload).ok(() => navigate(routes.manufacturing.baseline.overview));
            }}
          >
            {(formik) => (
              <>
                <StickyHeader className='mx-6'>
                  <div className='flex items-center gap-x-4'>
                    <button
                      onClick={() => {
                        if (step === 1) {
                          navigate(-1);
                        } else {
                          setStep((current) => current - 1);
                          formik.setFieldValue('products', []);
                        }
                      }}
                      className='flex items-center justify-center bg-[#E8EAF5] rounded-lg w-8 h-8 hover:bg-white hover:border-2 hover:border-brand'
                    >
                      <FontAwesomeIcon className='text-xl text-brand' icon={regular('chevron-left')} />
                    </button>
                    <div className='text-xl font-semibold'>New Baseline Report</div>
                  </div>
                  <div className='border rounded-full px-4 py-2 text-xs uppercase'>
                    step {step}/{selectedMethod === ManufacturingReportCreationMethod.Import ? 3 : 4}
                  </div>
                </StickyHeader>
                <div className='flex flex-col gap-y-4 mx-6'>
                  {(() => {
                    switch (step) {
                      case 1:
                        return <Step1 onNext={() => setStep(2)} selectedMethod={selectedMethod} setSelectedMethod={setSelectedMethod} />;
                      case 2:
                        return (
                          <Step2
                            step={step}
                            onNext={() => setStep(3)}
                            startDate={startDate}
                            setStartDate={setStartDate}
                            endDate={endDate}
                            setEndDate={setEndDate}
                          />
                        );
                      case 3:
                        return selectedMethod === ManufacturingReportCreationMethod.Manual ? (
                          <ManualStep3
                            step={step}
                            parentRef={parentRef}
                            startDate={startDate}
                            endDate={endDate}
                            onNext={() => setStep(4)}
                          />
                        ) : (
                          <ImportStep3
                            title={reportTitle}
                            startDate={startDate}
                            endDate={endDate}
                            onNext={() => setTriedSubmitting(true)}
                          />
                        );

                      case 4:
                        return (
                          <ManualStep4
                            startDate={startDate}
                            endDate={endDate}
                            reportTitle={reportTitle}
                            onSubmitClick={() => setTriedSubmitting(true)}
                          />
                        );
                    }
                  })()}
                </div>
              </>
            )}
          </Formik>
        </>
      ) : (
        <ManufacturingSkeletonLoader />
      )}
    </div>
  );
};
