import { duotone, regular, solid } from '@fortawesome/fontawesome-svg-core/import.macro';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import cn from 'classnames';
import { useFormikContext } from 'formik';
import cloneDeep from 'lodash/cloneDeep';
import sortBy from 'lodash/sortBy';
import { createRef, CSSProperties, ReactNode, RefObject, useEffect, useLayoutEffect, useRef, useState } from 'react';
import { NavLink, useNavigate } from 'react-router-dom';
import {
  bulkRemovableTags,
  ConsumptionNode,
  FinalDestinationNode,
  GenericNode,
  IngredientType,
  LifeCycleStage,
  NodeType,
  Position,
  ProductionNode,
  ProductionWarehouseNode,
  ProductState,
  ProductType,
  ProductV3,
  RawMaterialNode,
  RawMaterialSupplierNode,
  saveProductV3,
  StorageNode,
  StoreNode,
  Tag,
  updateProductV3,
  visibleExtractedDataTags,
  WarehouseNode,
} from '../../../../api';
import { Menu } from '../../../../components/Menu';
import { ModalFormApi } from '../../../../components/ModalForm';
import { ModalApi } from '../../../../components/ModalV3';
import { renderBadges } from './Badges';
import { BadgesHelpModal, skipBadgesHelpKey } from './BadgesHelp';
import { ConsumptionLocationDetails } from './ConsumptionLocationDetails';
import { ConsumptionLocationGraph } from './ConsumptionLocationGraph';
import { CopyDistribution } from './CopyDistribution';
import { CopyRawMaterials } from './CopyRawMaterials';
import { DisposalDetails } from './DisposalDetails';
import { FinalDestinationDetails } from './FinalDestinationDetails';
import { GridSize } from './GridSize';
import { IngredientDetails } from './IngredientDetails';
import { ConsumptionLocationInstructions, MainInstructions, ProductionFacilityInstructions } from './Instructions';
import { LifecycleStagePlaceholder } from './LifecycleStagePlaceholder';
import { ListLayoutBars, ListLayoutView } from './ListLayoutView';
import { Message, MessageId } from './Message';
import { PackagingDetails } from './PackagingDetails';
import { ProductionFacilityDetails } from './ProductionFacilityDetails';
import { ProductionFacilityGraph } from './ProductionFacilityGraph';
import { ProductionWarehouseDetails } from './ProductionWarehouseDetails';
import { getOptionalChecks, SaveModal } from './SaveModal';
import { Layout, SecondaryBarContent } from './SecondaryBarContent';
import { StepsUntaggingModal } from './StepsUntaggingModal';
import { StorageItems } from './StorageItems';
import { StoreDetails } from './StoreDetails';
import { Props as TitleBadgesProps, TitleBadges } from './TitleBadges';
import { TransportDetails } from './TransportDetails';
import { TransportLink } from './TransportLink';
import { ValidationErrors } from './ValidationErrors';
import { WarehouseDetails } from './WarehouseDetails';
import {
  canApplyDefaults,
  getConsumptionLocations,
  getDisposals,
  getFinalDestinations,
  getIngredients,
  getMovableNodesFromAll,
  getPackagings,
  getPrimaryAndMaterialSuppliers,
  getPrimaryTag,
  getProductionFacilities,
  getProductionWarehouses,
  getStores,
  getTransports,
  getValidationMessages,
  getWarehouses,
  hasValidationMessage,
  isPlaceholder,
  onEdit,
  onEditFromMulti,
  onSaveTransport,
  remove,
  sizeToPositions,
  sortAndFilterNodes,
} from './dataModel';
import {
  getBorderStyle,
  getConsumptionMenuItems,
  getDistributionMenuItems,
  getIngredientMenuItems,
  getPackagingMenuItems,
  getProductionMenuItems,
  renderDistributionAddButtons,
  renderProductionAddButtons,
  renderRawMaterialsAddButtons,
  renderUseAddButtons,
} from './shared';
import { useAppRoutes } from '../../../../hooks/useAppRoutes';

enum InnerView {
  ProductionFacility,
  Storage,
  ConsumptionLocation,
}

interface DragState {
  mouseDown: { x: number; y: number };
  nodeId: string;
  inProgress: boolean;
}

interface Props extends TitleBadgesProps {
  title: string;
  canUndo: boolean;
  canRedo: boolean;
  onUndo: () => void;
  onRedo: () => void;
  revision: boolean;
}

export const Graph = (props: Props) => {
  const navigate = useNavigate();
  const { routes } = useAppRoutes();
  const formik = useFormikContext<ProductV3>();
  const { waiting } = props;
  const savedScroll = useRef({ left: 0, top: 0 });
  const [hoveredInnerViewButtonNodeId, setHoveredInnerViewButtonNodeId] = useState('');
  const [hoveredMenuButtonNodeId, setHoveredMenuButtonNodeId] = useState('');
  const [dragState, setDragState] = useState<DragState | null>(null);

  const stepsUntaggingModal = useRef<ModalFormApi>(null);
  const saveModal = useRef<ModalFormApi>(null);
  const badgesHelpModal = useRef<ModalFormApi>(null);
  const [exportRawMaterialNode, setExportRawMaterialNode] = useState<RawMaterialNode>();
  const exportRawMaterialModal = useRef<ModalApi>(null);
  const importDistributionModal = useRef<ModalApi>(null);
  const exportDistributionModal = useRef<ModalApi>(null);
  const addPackagingModal = useRef<ModalFormApi>(null);
  const addWarehouseModal = useRef<ModalFormApi>(null);
  const addStoreModal = useRef<ModalFormApi>(null);

  const [layout, setLayout] = useState(Layout.Graph);
  const [listStage, setListStage] = useState(LifeCycleStage.RawMaterials);
  const [listFilter, setListFilter] = useState(new Array<NodeType>());
  const [listSearch, setListSearch] = useState('');

  const [innerView, setInnerView] = useState<{ name: InnerView; nodeId: string } | undefined>();
  const [ignoreLessImportantValidation, setIgnoreLessImportantValidation] = useState(!props.revision ?? true);
  const [savingBeforeExit, setSavingBeforeExit] = useState(false);

  const draft = formik.values.state === ProductState.Draft;
  const final = formik.values.productType === ProductType.Final;
  const intermediate = formik.values.productType === ProductType.Intermediate;
  const internal = formik.values.productType === ProductType.Internal;

  const hasValidationMessages = getValidationMessages(formik, { ignoreLessImportant: false }).length > 0;
  const canSaveCompleteAsDraft = formik.values.validation.errors.length === 0;
  const canViewReportWhenDraft = !ignoreLessImportantValidation && formik.values.reportCalculable;
  const showingValidation =
    (!ignoreLessImportantValidation && hasValidationMessages) || getValidationMessages(formik, { ignoreLessImportant: true }).length > 0;

  const nodeRefs = useRef<Record<string, RefObject<HTMLDivElement>>>({});
  const lifeCycleBoxRefs = useRef<Record<LifeCycleStage, RefObject<HTMLDivElement>>>({
    [LifeCycleStage.RawMaterials]: createRef(),
    [LifeCycleStage.Production]: createRef(),
    ...(final || intermediate
      ? {
          [LifeCycleStage.Distribution]: createRef(),
        }
      : ({} as any)),
    ...(final
      ? {
          [LifeCycleStage.Use]: createRef(),
          [LifeCycleStage.EndOfLife]: createRef(),
        }
      : ({} as any)),
  });
  const scrollableRef = useRef<HTMLDivElement>(null);
  const productionSlotsRefs = useRef<Record<string, RefObject<HTMLDivElement>>>({});
  const distributionSlotsRefs = useRef<Record<string, RefObject<HTMLDivElement>>>({});

  const showGetStarted = canApplyDefaults(formik);
  const [message, setMessage] = useState<MessageId | ''>(props.revision ? 'restoreRevision' : showGetStarted ? 'getStarted' : '');
  const messageRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (
      formik.values.metadata.system.flatMap(({ tags }) => tags).some((tag) => visibleExtractedDataTags.includes(tag)) &&
      localStorage.getItem(skipBadgesHelpKey) !== 'true'
    ) {
      badgesHelpModal.current!.open();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (!showGetStarted && !props.revision) {
      setMessage('');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [showGetStarted]);

  useLayoutEffect(() => {
    if (innerView) {
      scrollableRef.current!.scrollTo(0, 0);
    } else {
      scrollableRef.current!.scrollTo(savedScroll.current);
    }
  }, [innerView]);

  useEffect(() => {
    const onMouseUp = () => {
      setDragState(null);
    };

    window.addEventListener('mouseup', onMouseUp);

    return () => window.removeEventListener('mouseup', onMouseUp);
  }, [dragState]);

  useEffect(() => {
    if (dragState) {
      const onMouseMove = (event: MouseEvent) => {
        if (!waiting && dragState.inProgress) {
          const hoveredSlot = [
            ...Object.keys(productionSlotsRefs.current).map((position) => ({
              position,
              ref: productionSlotsRefs.current[position].current,
            })),
            ...Object.keys(distributionSlotsRefs.current).map((position) => ({
              position,
              ref: distributionSlotsRefs.current[position].current,
            })),
          ]
            .filter(({ ref }) => ref)
            .find(({ ref }) => {
              const rect = ref!.getBoundingClientRect();
              return event.clientX >= rect.left && event.clientX <= rect.right && event.clientY >= rect.top && event.clientY <= rect.bottom;
            });

          if (hoveredSlot) {
            formik.setValues((oldValues) => {
              const newValues = cloneDeep(oldValues);
              getMovableNodesFromAll(newValues.nodes).find((node) => node.id === dragState.nodeId)!.layoutPosition = {
                x: parseInt(hoveredSlot.position.split('.')[0]),
                y: parseInt(hoveredSlot.position.split('.')[1]),
              };
              return newValues;
            });
          }
        } else if (Math.max(Math.abs(event.clientX - dragState.mouseDown.x), Math.abs(event.clientY - dragState.mouseDown.y)) > 5) {
          setDragState((oldState) => (oldState ? { ...oldState, inProgress: true } : oldState));
        }
      };

      window.addEventListener('mousemove', onMouseMove);

      return () => window.removeEventListener('mousemove', onMouseMove);
    }
  }, [dragState, formik, waiting]);

  getPrimaryAndMaterialSuppliers(formik).forEach((node) => {
    nodeRefs.current[node.id] = nodeRefs.current[node.id] ?? createRef();
  });

  sizeToPositions(formik.values.layoutGrids.production).forEach((position) => {
    productionSlotsRefs.current[`${position.x}.${position.y}`] = productionSlotsRefs.current[`${position.x}.${position.y}`] ?? createRef();
  });

  sizeToPositions(formik.values.layoutGrids.distribution).forEach((position) => {
    distributionSlotsRefs.current[`${position.x}.${position.y}`] =
      distributionSlotsRefs.current[`${position.x}.${position.y}`] ?? createRef();
  });

  const openExportRawMaterialModal = (node: RawMaterialNode) => {
    setExportRawMaterialNode(node);
    exportRawMaterialModal.current!.open();
  };

  const getNodeStyle = (node?: GenericNode) =>
    cn(
      getBorderStyle(node, formik, { ignoreLessImportantValidation }),
      (() => {
        if (node) {
          if (dragState?.inProgress && dragState.nodeId === node.id) {
            return '';
          }

          if (isPlaceholder(node) || getPrimaryTag(node, formik) === Tag.Default) {
            return 'bg-slate-50';
          }

          return 'bg-white';
        }

        return 'bg-neutral-50';
      })(),
    );

  const getSupplierStyle = (node: RawMaterialSupplierNode) =>
    cn(
      'border',
      (() => {
        if (hasValidationMessage(node, formik, { ignoreLessImportant: ignoreLessImportantValidation })) {
          return 'border-red-500 bg-zinc-50';
        }

        return cn(
          'border-transparent',
          getPrimaryTag(node, formik) === Tag.Default ? 'bg-slate-50' : 'bg-zinc-50',
          'shadow-[0_0_2px_0_rgba(0,0,0,0.2)]',
        );
      })(),
    );

  const onViewBack = () => {
    if (
      innerView!.name !== InnerView.Storage &&
      formik.values.metadata.system.some(
        ({ path, tags }) => path.endsWith(`${innerView!.nodeId}/steps`) && tags.some((tag) => bulkRemovableTags.includes(tag)),
      )
    ) {
      stepsUntaggingModal.current!.open();
    } else {
      setInnerView(undefined);
    }
  };

  const saveThenNavigateToReport = (options?: { untag: boolean }) => {
    const valuesForSave = cloneDeep(formik.values);

    if (options?.untag) {
      valuesForSave.metadata.user.forEach((item) => {
        item.tags = item.tags.filter((tag) => !bulkRemovableTags.includes(tag));
      });
      valuesForSave.metadata.user = valuesForSave.metadata.user.filter(({ tags }) => tags.length > 0);
    }

    setSavingBeforeExit(true);
    (hasValidationMessages ? updateProductV3 : saveProductV3)(valuesForSave).call({
      ok: () => navigate(routes.products.productOverview.production(formik.values.id)),
      fail: () => setSavingBeforeExit(false),
    });
  };

  return (
    <div className='flex flex-col'>
      <StepsUntaggingModal
        modalRef={stepsUntaggingModal}
        onConfirm={({ untag }) => {
          if (untag) {
            formik.setValues((values) => {
              const newValues = cloneDeep(values);
              newValues.metadata.user
                .filter(({ path }) => path.includes(`${innerView!.nodeId}/steps/`))
                .forEach((item) => {
                  item.tags = item.tags.filter((tag) => !bulkRemovableTags.includes(tag));
                });
              newValues.metadata.user = newValues.metadata.user.filter(({ tags }) => tags.length > 0);
              return newValues;
            });
          }

          setInnerView(undefined);
        }}
      />
      <SaveModal
        modalRef={saveModal}
        draft={draft}
        addPackagingModal={addPackagingModal}
        addWarehouseModal={addWarehouseModal}
        addStoreModal={addStoreModal}
        goToProductionFacility={(node) => {
          setInnerView({ name: InnerView.ProductionFacility, nodeId: node.id });
        }}
        goToConsumptionLocation={(node) => {
          setInnerView({ name: InnerView.ConsumptionLocation, nodeId: node.id });
        }}
        onConfirm={saveThenNavigateToReport}
      />
      <BadgesHelpModal ref={badgesHelpModal} showSkip />
      <CopyRawMaterials node={exportRawMaterialNode} exportModal={exportRawMaterialModal} {...props} />
      <CopyDistribution importModal={importDistributionModal} exportModal={exportDistributionModal} {...props} />
      <div className='divide-y divide-zinc-300 -mx-6 xl:-mx-[calc((100vw-theme(screens.xl))/2+theme(spacing.6))]'>
        <div className='flex justify-center pb-4'>
          <div className='flex justify-between gap-12 px-12 w-[theme(screens.xl)]'>
            <div className='flex gap-4 truncate'>
              <button
                type='button'
                className='shrink-0 flex justify-center items-center w-8 aspect-square rounded-md text-lg text-brand bg-[#E8EAF5] active:scale-90'
                onClick={() => {
                  if (innerView) {
                    onViewBack();
                  } else {
                    navigate('../info');
                  }
                }}
              >
                <FontAwesomeIcon icon={solid('chevron-left')} />
              </button>
              <div className='flex items-center gap-2 text-base truncate'>
                <NavLink to={routes.products.list} className='text-zinc-500'>
                  Products
                </NavLink>
                <FontAwesomeIcon size='xs' icon={solid('chevron-right')} className='text-zinc-500' />
                <NavLink to='../info' className='text-zinc-500 truncate'>
                  {props.title} details
                </NavLink>
                <FontAwesomeIcon size='xs' icon={solid('chevron-right')} className='text-zinc-500' />
                <button
                  type='button'
                  className={cn(innerView ? 'text-zinc-500' : 'font-semibold', 'truncate')}
                  disabled={!innerView}
                  onClick={onViewBack}
                >
                  Product life cycle
                </button>
                {innerView && (
                  <>
                    <FontAwesomeIcon size='xs' icon={solid('chevron-right')} className='text-zinc-500' />
                    <div className='font-semibold'>{formik.values.nodes.find(({ id }) => id === innerView.nodeId)!.displayName}</div>
                  </>
                )}
                <TitleBadges {...props} />
              </div>
            </div>
            <div className='shrink-0 flex gap-4'>
              {innerView?.name === InnerView.ProductionFacility && <ProductionFacilityInstructions />}
              {innerView?.name === InnerView.ConsumptionLocation && <ConsumptionLocationInstructions />}
              {!innerView && (
                <>
                  <MainInstructions />
                  <button
                    type='button'
                    className={cn(
                      'bg-brand rounded-full px-4 py-1 text-white font-semibold',
                      '[&:active:not(:disabled)]:scale-95 disabled:bg-neutral-300',
                      {
                        'cursor-wait': savingBeforeExit,
                      },
                    )}
                    disabled={(showingValidation && !draft && !canSaveCompleteAsDraft) || savingBeforeExit}
                    onClick={() => {
                      setIgnoreLessImportantValidation(false);

                      if (hasValidationMessages) {
                        setLayout(Layout.Graph);
                      }

                      if (
                        (draft && (!hasValidationMessages || canViewReportWhenDraft)) ||
                        (!draft && (!hasValidationMessages || canSaveCompleteAsDraft))
                      ) {
                        if (formik.values.productType === ProductType.Internal) {
                          setLayout(Layout.Graph);
                          saveModal.current!.open();
                        } else if (draft && getOptionalChecks(formik).length === 0) {
                          saveThenNavigateToReport();
                        } else {
                          setLayout(Layout.Graph);
                          saveModal.current!.open();
                        }
                      }
                    }}
                  >
                    {(() => {
                      if (savingBeforeExit) {
                        return 'Saving changes…';
                      }

                      if (draft) {
                        if (hasValidationMessages && canViewReportWhenDraft) {
                          return 'View temporary report';
                        }

                        return 'View report';
                      }

                      if (hasValidationMessages && canSaveCompleteAsDraft) {
                        return 'Save as draft';
                      }

                      if (props.revision) {
                        return 'Restore';
                      }

                      return 'Save changes';
                    })()}
                  </button>
                </>
              )}
              {showingValidation && (
                <ValidationErrors selectedNodeId={innerView?.nodeId} ignoreLessImportant={ignoreLessImportantValidation} />
              )}
            </div>
          </div>
        </div>
        <div className='flex justify-center bg-slate-50 py-3'>
          <div className='flex justify-between px-12 w-[theme(screens.xl)]'>
            <SecondaryBarContent {...props} layout={layout} setLayout={setLayout} onAppliedDefaults={() => setMessage('appliedDefaults')} />
          </div>
        </div>
        {layout === Layout.List && (
          <ListLayoutBars
            message={message}
            setMessage={setMessage}
            stage={listStage}
            setStage={setListStage}
            filter={listFilter}
            setFilter={setListFilter}
            search={listSearch}
            setSearch={setListSearch}
            messageRef={messageRef}
            addPackagingModal={addPackagingModal}
            addWarehouseModal={addWarehouseModal}
            addStoreModal={addStoreModal}
          />
        )}
      </div>
      <div
        ref={scrollableRef}
        style={
          {
            '--list-layout': layout === Layout.List ? '1' : '0',
            '--list-message-space': `${
              layout === Layout.List && message && messageRef.current ? messageRef.current.getBoundingClientRect().height + 16 : 0
            }px`,
          } as CSSProperties
        }
        className={cn(
          'relative overflow-scroll h-[calc(100vh-theme(spacing.1)*(50.5+var(--list-layout)*30.5)-var(--list-message-space))]',
          '-mx-6 xl:-mx-[calc((100vw-theme(screens.xl))/2+theme(spacing.6))]',
          'flex bg-neutral-50 border-t border-zinc-300',
        )}
      >
        {(() => {
          const padding = 'w-[calc((100vw-theme(screens.xl))/2)] shrink-0';

          return (
            <>
              <div className={padding}></div>
              <div className='flex-1 flex flex-col'>
                {layout === Layout.List && (
                  <div className='flex flex-col px-12 w-[theme(screens.xl)] min-h-full'>
                    <ListLayoutView
                      stage={listStage}
                      filter={listFilter}
                      search={listSearch}
                      ignoreLessImportantValidation={ignoreLessImportantValidation}
                      openExportRawMaterialModal={openExportRawMaterialModal}
                    />
                  </div>
                )}
                {layout === Layout.Graph && (
                  <>
                    {(!message || innerView) && <div className='h-12 shrink-0'></div>}
                    {innerView?.name === InnerView.ProductionFacility && (
                      <ProductionFacilityGraph
                        data={formik.values.nodes.find(({ id }) => id === innerView.nodeId) as ProductionNode}
                        scrollableRef={scrollableRef}
                        waiting={waiting}
                        ignoreLessImportantValidation={ignoreLessImportantValidation}
                        onBack={onViewBack}
                      />
                    )}
                    {innerView?.name === InnerView.Storage && (
                      <StorageItems
                        data={formik.values.nodes.find(({ id }) => id === innerView.nodeId) as StorageNode}
                        onBack={onViewBack}
                      />
                    )}
                    {innerView?.name === InnerView.ConsumptionLocation && (
                      <ConsumptionLocationGraph
                        data={formik.values.nodes.find(({ id }) => id === innerView.nodeId) as ConsumptionNode}
                        scrollableRef={scrollableRef}
                        waiting={waiting}
                        ignoreLessImportantValidation={ignoreLessImportantValidation}
                        onBack={onViewBack}
                      />
                    )}
                    {!innerView && (
                      <>
                        {message && (
                          <div className='px-12 py-4 max-w-screen-xl'>
                            <Message id={message} layout={layout} onClose={() => setMessage('')} />
                          </div>
                        )}
                        <div
                          className={cn('flex-1 text-xs px-12 grid gap-x-3', '[--default-column-width:theme(width.64)]', {
                            'grid-cols-[min(var(--default-column-width))_repeat(2,max-content)_min(theme(width.52))_min(var(--default-column-width))]':
                              final,
                            'grid-cols-[min(var(--default-column-width))_repeat(2,max-content)]': intermediate,
                            'grid-cols-[min(var(--default-column-width))_repeat(1,max-content)]': internal,
                            'cursor-move': dragState?.inProgress,
                            'cursor-wait': savingBeforeExit,
                          })}
                        >
                          <div className='flex flex-col rounded-lg bg-white'>
                            <div className='flex flex-col justify-center items-center gap-4 py-4 rounded-t-lg bg-[#E8EAF5]'>
                              <div className='text-zinc-700 font-semibold'>Raw Materials</div>
                              <div className='flex gap-2'>{renderRawMaterialsAddButtons(addPackagingModal, formik)}</div>
                            </div>
                            <div ref={lifeCycleBoxRefs.current[LifeCycleStage.RawMaterials]} className='flex flex-col gap-5 p-6'>
                              {getIngredients(formik).length === 0 && getPackagings(formik).length === 0 && (
                                <div className='text-zinc-400 text-center'>
                                  <LifecycleStagePlaceholder stage={LifeCycleStage.RawMaterials} />
                                </div>
                              )}
                              {sortAndFilterNodes(getIngredients(formik), formik, { ignoreLessImportantValidation }).map((ingredient) => (
                                <IngredientDetails key={ingredient.id} data={ingredient} onSave={(params) => onEdit(params, formik)}>
                                  <div className={cn('relative', { 'pointer-events-none': waiting })}>
                                    {ingredient.flagged && (
                                      <FontAwesomeIcon
                                        icon={solid('flag')}
                                        className='absolute -right-4 text-brandDarkPurple2 pointer-events-none'
                                      />
                                    )}
                                    <div
                                      ref={nodeRefs.current[ingredient.id]}
                                      className={cn(
                                        'text-zinc-700 cursor-pointer flex-1 flex flex-col gap-3 rounded-xl p-1.5',
                                        'transition',
                                        getNodeStyle(ingredient),
                                        {
                                          'hover:bg-green-50': hoveredMenuButtonNodeId !== ingredient.id,
                                        },
                                      )}
                                    >
                                      <div className='flex gap-0.5 items-center justify-between'>
                                        <div className='flex items-center gap-2 truncate'>
                                          <div
                                            className={cn(
                                              'relative flex justify-center items-center bg-green-50 text-green-900 rounded-md h-7 aspect-square',
                                            )}
                                          >
                                            <FontAwesomeIcon icon={duotone('carrot')} />
                                            {ingredient.ingredient.type === IngredientType.IntermediateProduct && (
                                              <FontAwesomeIcon
                                                icon={solid('circle-arrow-down')}
                                                className='text-[7px] absolute right-[7px] bottom-[7px]'
                                              />
                                            )}
                                          </div>
                                          <div className='font-semibold truncate'>{ingredient.displayName}</div>
                                        </div>
                                        <div onClick={(event) => event.stopPropagation()}>
                                          <Menu
                                            zIndex={20}
                                            placement='bottom-start'
                                            items={getIngredientMenuItems(
                                              ingredient,
                                              formik,
                                              openExportRawMaterialModal,
                                              routes.products.productGraph(ingredient.ingredient.id),
                                            )}
                                          >
                                            {({ open }) => (
                                              <button
                                                type='button'
                                                onMouseEnter={() => setHoveredMenuButtonNodeId(ingredient.id)}
                                                onMouseLeave={() => setHoveredMenuButtonNodeId('')}
                                                className={cn('flex hover:text-inherit', { 'text-zinc-400': !open })}
                                              >
                                                <FontAwesomeIcon className='h-4 aspect-square' icon={solid('ellipsis-vertical')} />
                                              </button>
                                            )}
                                          </Menu>
                                        </div>
                                      </div>
                                      <div className='flex flex-col gap-3'>
                                        {sortAndFilterNodes(ingredient.nodes, formik, { ignoreLessImportantValidation }).map((supplier) => (
                                          <div
                                            key={supplier.id}
                                            ref={nodeRefs.current[supplier.id]}
                                            title={supplier.displayName}
                                            className={cn('flex flex-col gap-1.5 p-1.5 rounded-md', getSupplierStyle(supplier))}
                                          >
                                            <div className='truncate'>{supplier.displayName}</div>
                                            {renderBadges(supplier, formik)}
                                          </div>
                                        ))}
                                      </div>
                                      {renderBadges(ingredient, formik)}
                                    </div>
                                  </div>
                                </IngredientDetails>
                              ))}
                              {sortAndFilterNodes(getPackagings(formik), formik, { ignoreLessImportantValidation }).map((packaging) => (
                                <PackagingDetails key={packaging.id} data={packaging} onSave={(params) => onEdit(params, formik)}>
                                  <div className={cn('relative', { 'pointer-events-none': waiting })}>
                                    {packaging.flagged && (
                                      <FontAwesomeIcon
                                        icon={solid('flag')}
                                        className='absolute -right-4 text-brandDarkPurple2 pointer-events-none'
                                      />
                                    )}
                                    <div
                                      ref={nodeRefs.current[packaging.id]}
                                      className={cn(
                                        'text-zinc-700 cursor-pointer flex-1 flex flex-col gap-3 rounded-xl p-1.5',
                                        'transition',
                                        getNodeStyle(packaging),
                                        {
                                          'hover:bg-lime-50': hoveredMenuButtonNodeId !== packaging.id,
                                        },
                                      )}
                                    >
                                      <div className='flex gap-0.5 items-center justify-between'>
                                        <div className='flex items-center gap-2 truncate'>
                                          <div className='flex justify-center items-center bg-lime-50 text-lime-900 rounded-md h-7 aspect-square'>
                                            <FontAwesomeIcon icon={duotone('box-open')} />
                                          </div>
                                          <div className='font-semibold truncate'>{packaging.displayName}</div>
                                        </div>
                                        <div onClick={(event) => event.stopPropagation()}>
                                          <Menu
                                            zIndex={20}
                                            placement='bottom-start'
                                            items={getPackagingMenuItems(packaging, formik, openExportRawMaterialModal)}
                                          >
                                            {({ open }) => (
                                              <button
                                                type='button'
                                                onMouseEnter={() => setHoveredMenuButtonNodeId(packaging.id)}
                                                onMouseLeave={() => setHoveredMenuButtonNodeId('')}
                                                className={cn('flex hover:text-inherit', { 'text-zinc-400': !open })}
                                              >
                                                <FontAwesomeIcon className='h-4 aspect-square' icon={solid('ellipsis-vertical')} />
                                              </button>
                                            )}
                                          </Menu>
                                        </div>
                                      </div>
                                      <div className='flex flex-col gap-3'>
                                        {sortAndFilterNodes(packaging.nodes, formik, { ignoreLessImportantValidation }).map((supplier) => (
                                          <div
                                            key={supplier.id}
                                            ref={nodeRefs.current[supplier.id]}
                                            title={supplier.displayName}
                                            className={cn('flex flex-col gap-1.5 p-1.5 rounded-md', getSupplierStyle(supplier))}
                                          >
                                            <div className='truncate'>{supplier.displayName}</div>
                                            {renderBadges(supplier, formik)}
                                          </div>
                                        ))}
                                      </div>
                                      {renderBadges(packaging, formik)}
                                    </div>
                                  </div>
                                </PackagingDetails>
                              ))}
                            </div>
                          </div>
                          <div className='flex flex-col rounded-lg bg-white'>
                            {(() => {
                              const shorten =
                                formik.values.layoutGrids.production.width < 2 ||
                                (getProductionFacilities(formik).length === 0 && getProductionWarehouses(formik).length === 0);
                              return (
                                <div className='flex flex-col justify-center items-center gap-4 py-4 rounded-t-lg bg-[#E8EAF5]'>
                                  <div className='text-zinc-700 font-semibold'>Production</div>
                                  <div className='flex w-full'>
                                    <div className='flex-1'>
                                      <GridSize modelName='layoutGrids.production'>
                                        {() => (
                                          <button
                                            type='button'
                                            className={cn(
                                              shorten ? 'ml-2' : 'ml-3',
                                              shorten ? 'w-5' : 'aspect-square',
                                              'flex justify-center items-center rounded-lg h-full',
                                              'text-[#220066] bg-[#DACEFD] shadow-[0_0_2px_0_rgba(0,0,0,0.2)]',
                                              'active:scale-95',
                                            )}
                                          >
                                            <FontAwesomeIcon size={shorten ? 'sm' : 'lg'} icon={regular('table')} />
                                          </button>
                                        )}
                                      </GridSize>
                                    </div>
                                    <div className='flex gap-2'>{renderProductionAddButtons(formik, { shorten })}</div>
                                    <div className='flex-1'></div>
                                  </div>
                                </div>
                              );
                            })()}
                            <div
                              ref={lifeCycleBoxRefs.current[LifeCycleStage.Production]}
                              className={cn(
                                'p-6',
                                getProductionFacilities(formik).length + getProductionWarehouses(formik).length > 0
                                  ? 'grid gap-8'
                                  : 'w-52 flex flex-col',
                              )}
                              style={{
                                gridTemplateColumns: `repeat(${formik.values.layoutGrids.production.width}, min(10rem))`,
                              }}
                            >
                              {getProductionFacilities(formik).length === 0 && getProductionWarehouses(formik).length === 0 ? (
                                <div className='text-zinc-400 text-center'>
                                  <LifecycleStagePlaceholder stage={LifeCycleStage.Production} />
                                </div>
                              ) : (
                                (() => {
                                  const openDetails = (node: ProductionNode | ProductionWarehouseNode | undefined, children: ReactNode) =>
                                    node ? (
                                      node.type === NodeType.Production ? (
                                        <ProductionFacilityDetails key={node.id} data={node} onSave={(params) => onEdit(params, formik)}>
                                          {children}
                                        </ProductionFacilityDetails>
                                      ) : (
                                        <ProductionWarehouseDetails
                                          key={node.id}
                                          data={node}
                                          onSave={(params) => onEditFromMulti(params, formik)}
                                        >
                                          {children}
                                        </ProductionWarehouseDetails>
                                      )
                                    ) : (
                                      children
                                    );

                                  const render = (position?: Position, node?: ProductionNode | ProductionWarehouseNode) =>
                                    openDetails(
                                      node,
                                      <div
                                        key={position ? `${position.x}.${position.y}` : node!.id}
                                        className={cn('relative', { 'pointer-events-none': waiting, flex: !node })}
                                      >
                                        {node?.flagged && (
                                          <FontAwesomeIcon
                                            icon={solid('flag')}
                                            className='absolute -right-4 text-brandDarkPurple2 pointer-events-none'
                                          />
                                        )}
                                        <div
                                          ref={
                                            node ? nodeRefs.current[node.id] : productionSlotsRefs.current[`${position!.x}.${position!.y}`]
                                          }
                                          className={cn(
                                            'select-none text-zinc-700 flex-1 flex flex-col gap-3 rounded-xl p-1.5',
                                            'group/outer',
                                            getNodeStyle(node),
                                            {
                                              transition: node,
                                              [node?.type === NodeType.ProductionWarehouse
                                                ? 'cursor-pointer hover:bg-fuchsia-50'
                                                : 'cursor-pointer hover:bg-rose-50']:
                                                node &&
                                                !dragState?.inProgress &&
                                                ![hoveredInnerViewButtonNodeId, hoveredMenuButtonNodeId].includes(node.id),
                                              [node?.type === NodeType.ProductionWarehouse ? 'bg-fuchsia-50' : 'bg-rose-50']:
                                                dragState?.inProgress && dragState.nodeId === node?.id,
                                            },
                                          )}
                                          onMouseDown={
                                            node
                                              ? (event) => {
                                                  setDragState({
                                                    mouseDown: { x: event.clientX, y: event.clientY },
                                                    nodeId: node.id,
                                                    inProgress: false,
                                                  });
                                                }
                                              : undefined
                                          }
                                        >
                                          <div className='flex gap-0.5 items-center justify-between'>
                                            <div className='flex items-center gap-2 truncate'>
                                              <div
                                                className={cn(
                                                  'flex justify-center items-center rounded-md h-7 aspect-square',
                                                  node?.type === NodeType.ProductionWarehouse
                                                    ? 'bg-fuchsia-50 text-fuchsia-900'
                                                    : 'bg-rose-50 text-rose-900',
                                                  {
                                                    invisible: !node,
                                                  },
                                                )}
                                              >
                                                <FontAwesomeIcon
                                                  icon={
                                                    node?.type === NodeType.ProductionWarehouse
                                                      ? duotone('warehouse-full')
                                                      : duotone('industry-windows')
                                                  }
                                                />
                                              </div>
                                              <div className='flex flex-col gap-0.5 justify-center truncate'>
                                                <div className='font-semibold truncate'>{node?.displayName ?? <>&nbsp;</>}</div>
                                                {node?.type === NodeType.Production && node.finalFacility && (
                                                  <div className='text-[8px] leading-none tracking-wider font-semibold text-rose-900 uppercase'>
                                                    Final
                                                  </div>
                                                )}
                                              </div>
                                            </div>
                                            {node && (
                                              <div onClick={(event) => event.stopPropagation()}>
                                                <Menu zIndex={20} placement='bottom-start' items={getProductionMenuItems(node, formik)}>
                                                  {({ open }) => (
                                                    <button
                                                      type='button'
                                                      onMouseEnter={() => setHoveredMenuButtonNodeId(node.id)}
                                                      onMouseLeave={() => setHoveredMenuButtonNodeId('')}
                                                      className={cn('flex hover:text-inherit', { 'text-zinc-400': !open })}
                                                    >
                                                      <FontAwesomeIcon className='h-4 aspect-square' icon={solid('ellipsis-vertical')} />
                                                    </button>
                                                  )}
                                                </Menu>
                                              </div>
                                            )}
                                          </div>
                                          <button
                                            type='button'
                                            className={cn(
                                              'flex flex-col gap-1.5 px-1.5 py-1 rounded-md',
                                              'border',
                                              node?.type === NodeType.Production ? 'bg-rose-50' : 'bg-fuchsia-50',
                                              node?.type === NodeType.Production &&
                                                node.steps.some((step) =>
                                                  hasValidationMessage(step, formik, {
                                                    ignoreLessImportant: ignoreLessImportantValidation,
                                                  }),
                                                )
                                                ? 'border-red-500'
                                                : 'border-transparent',
                                              'group/inner',
                                              {
                                                invisible: !node,
                                                [cn(
                                                  node?.type === NodeType.Production ? 'hover:bg-rose-200' : 'hover:bg-fuchsia-200',
                                                  'hover:shadow-[0_0_2px_0_rgba(0,0,0,0.2)]',
                                                )]: node && !dragState?.inProgress,
                                                'group-hover/outer:bg-neutral-50':
                                                  node && !dragState?.inProgress && hoveredInnerViewButtonNodeId !== node.id,
                                                'cursor-move': dragState?.inProgress,
                                              },
                                            )}
                                            onMouseEnter={() => setHoveredInnerViewButtonNodeId(node!.id)}
                                            onMouseLeave={() => setHoveredInnerViewButtonNodeId('')}
                                            onClick={(event) => {
                                              event.stopPropagation();
                                              savedScroll.current = {
                                                left: scrollableRef.current!.scrollLeft,
                                                top: scrollableRef.current!.scrollTop,
                                              };
                                              setInnerView({
                                                name: node?.type === NodeType.Production ? InnerView.ProductionFacility : InnerView.Storage,
                                                nodeId: node!.id,
                                              });
                                            }}
                                          >
                                            <div className='flex gap-1.5 items-center'>
                                              <div
                                                className={cn(
                                                  'rounded-2xl px-1.5 py-0.5 bg-white ',
                                                  node?.type === NodeType.Production ? 'text-rose-900' : 'text-fuchsia-900',
                                                  {
                                                    'group-hover/outer:text-inherit':
                                                      node && !dragState?.inProgress && hoveredInnerViewButtonNodeId !== node.id,
                                                    [node?.type === NodeType.Production
                                                      ? 'group-hover/inner:text-rose-900'
                                                      : 'group-hover/inner:text-fuchsia-900']: node && !dragState?.inProgress,
                                                  },
                                                )}
                                              >
                                                {node ? (node.type === NodeType.Production ? node.steps : node.items).length : 0}
                                              </div>
                                              <div
                                                className={cn({
                                                  'group-hover/outer:text-zinc-500':
                                                    node && !dragState?.inProgress && hoveredInnerViewButtonNodeId !== node.id,
                                                  'group-hover/inner:text-inherit': node && !dragState?.inProgress,
                                                })}
                                              >
                                                {node ? (
                                                  node.type === NodeType.Production ? (
                                                    <>Production step{node.steps.length === 1 ? '' : 's'}</>
                                                  ) : (
                                                    <>Stored item{node.items.length === 1 ? '' : 's'}</>
                                                  )
                                                ) : (
                                                  <>&nbsp;</>
                                                )}
                                              </div>
                                            </div>
                                            {renderBadges(node, formik, { subNodes: true })}
                                          </button>
                                          {renderBadges(node, formik)}
                                        </div>
                                      </div>,
                                    );

                                  return (
                                    <>
                                      {sizeToPositions(formik.values.layoutGrids.production).map((position) =>
                                        render(
                                          position,
                                          [...getProductionFacilities(formik), ...getProductionWarehouses(formik)]
                                            .filter(({ displayName }) => displayName)
                                            .find((node) => node.layoutPosition?.x === position.x && node.layoutPosition?.y === position.y),
                                        ),
                                      )}
                                      {[...getProductionFacilities(formik), ...getProductionWarehouses(formik)]
                                        .filter(
                                          (node) =>
                                            !node.layoutPosition ||
                                            node.layoutPosition.x >= formik.values.layoutGrids.production.width ||
                                            node.layoutPosition.y >= formik.values.layoutGrids.production.height,
                                        )
                                        .map((node) => render(undefined, node))}
                                    </>
                                  );
                                })()
                              )}
                            </div>
                          </div>
                          {(final || intermediate) && (
                            <>
                              <div className='flex flex-col rounded-lg bg-white'>
                                {(() => {
                                  const shorten =
                                    formik.values.layoutGrids.distribution.width < 2 ||
                                    (getWarehouses(formik).length === 0 &&
                                      getStores(formik).length === 0 &&
                                      getFinalDestinations(formik).length === 0);
                                  return (
                                    <div className='flex flex-col justify-center items-center gap-4 py-4 rounded-t-lg bg-[#E8EAF5]'>
                                      <div className='text-zinc-700 font-semibold'>Distribution</div>
                                      <div className='flex w-full'>
                                        <div className='flex-1'>
                                          <GridSize modelName='layoutGrids.distribution'>
                                            {() => (
                                              <button
                                                type='button'
                                                className={cn(
                                                  shorten ? 'ml-2' : 'ml-3',
                                                  shorten ? 'w-5' : 'aspect-square',
                                                  'flex justify-center items-center rounded-lg h-full',
                                                  'text-[#220066] bg-[#DACEFD] shadow-[0_0_2px_0_rgba(0,0,0,0.2)]',
                                                  'active:scale-95',
                                                )}
                                              >
                                                <FontAwesomeIcon size={shorten ? 'sm' : 'lg'} icon={regular('table')} />
                                              </button>
                                            )}
                                          </GridSize>
                                        </div>
                                        <div className='flex gap-2'>
                                          {renderDistributionAddButtons(addWarehouseModal, addStoreModal, formik, { shorten })}
                                        </div>
                                        <div className='flex-1 flex justify-end'>
                                          <Menu
                                            zIndex={20}
                                            placement='bottom-end'
                                            items={[
                                              {
                                                icon: regular('download'),
                                                label: 'Import "Distribution"',
                                                onClick: () => {
                                                  importDistributionModal.current!.open();
                                                },
                                              },
                                              {
                                                icon: regular('upload'),
                                                label: 'Export "Distribution"',
                                                onClick: () => {
                                                  exportDistributionModal.current!.open();
                                                },
                                              },
                                            ]}
                                          >
                                            {({ open }) => (
                                              <button
                                                type='button'
                                                className={cn('h-full w-10 mr-1 flex justify-center items-center hover:text-inherit', {
                                                  'text-zinc-400': !open,
                                                })}
                                              >
                                                <FontAwesomeIcon className='h-4 aspect-square' icon={solid('ellipsis-vertical')} />
                                              </button>
                                            )}
                                          </Menu>
                                        </div>
                                      </div>
                                    </div>
                                  );
                                })()}
                                <div
                                  ref={lifeCycleBoxRefs.current[LifeCycleStage.Distribution]}
                                  className={cn(
                                    'p-6',
                                    getWarehouses(formik).length + getStores(formik).length + getFinalDestinations(formik).length > 0
                                      ? 'grid gap-8'
                                      : 'w-52 flex flex-col',
                                  )}
                                  style={{
                                    gridTemplateColumns: `repeat(${formik.values.layoutGrids.distribution.width}, min(10rem))`,
                                  }}
                                >
                                  {getWarehouses(formik).length === 0 &&
                                  getStores(formik).length === 0 &&
                                  getFinalDestinations(formik).length === 0 ? (
                                    <div className='text-zinc-400 text-center'>
                                      <LifecycleStagePlaceholder stage={LifeCycleStage.Distribution} />
                                    </div>
                                  ) : (
                                    (() => {
                                      const openDetails = (
                                        node: WarehouseNode | StoreNode | FinalDestinationNode | undefined,
                                        children: ReactNode,
                                      ) =>
                                        node ? (
                                          node.type === NodeType.Warehouse ? (
                                            <WarehouseDetails
                                              key={node.id}
                                              data={node}
                                              onSave={(params) => onEditFromMulti(params, formik)}
                                            >
                                              {children}
                                            </WarehouseDetails>
                                          ) : node.type === NodeType.Store ? (
                                            <StoreDetails key={node.id} data={node} onSave={(params) => onEditFromMulti(params, formik)}>
                                              {children}
                                            </StoreDetails>
                                          ) : (
                                            <FinalDestinationDetails key={node.id} data={node} onSave={(params) => onEdit(params, formik)}>
                                              {children}
                                            </FinalDestinationDetails>
                                          )
                                        ) : (
                                          children
                                        );

                                      const render = (position?: Position, node?: WarehouseNode | StoreNode | FinalDestinationNode) =>
                                        openDetails(
                                          node,
                                          <div
                                            key={position ? `${position.x}.${position.y}` : node!.id}
                                            className={cn('relative', { 'pointer-events-none': waiting, flex: !node })}
                                          >
                                            {node?.flagged && (
                                              <FontAwesomeIcon
                                                icon={solid('flag')}
                                                className='absolute -right-4 text-brandDarkPurple2 pointer-events-none'
                                              />
                                            )}
                                            <div
                                              ref={
                                                node
                                                  ? nodeRefs.current[node.id]
                                                  : distributionSlotsRefs.current[`${position!.x}.${position!.y}`]
                                              }
                                              className={cn(
                                                'select-none text-zinc-700 flex-1 flex flex-col gap-3 rounded-xl p-1.5',
                                                'group/outer',
                                                getNodeStyle(node),
                                                {
                                                  transition: node,
                                                  [node?.type === NodeType.Warehouse
                                                    ? 'cursor-pointer hover:bg-fuchsia-50'
                                                    : 'cursor-pointer hover:bg-blue-50']:
                                                    node &&
                                                    !dragState?.inProgress &&
                                                    ![hoveredInnerViewButtonNodeId, hoveredMenuButtonNodeId].includes(node.id),
                                                  [node?.type === NodeType.Warehouse ? 'bg-fuchsia-50' : 'bg-blue-50']:
                                                    dragState?.inProgress && dragState.nodeId === node?.id,
                                                },
                                              )}
                                              onMouseDown={
                                                node
                                                  ? (event) => {
                                                      setDragState({
                                                        mouseDown: { x: event.clientX, y: event.clientY },
                                                        nodeId: node.id,
                                                        inProgress: false,
                                                      });
                                                    }
                                                  : undefined
                                              }
                                            >
                                              <div className='flex gap-0.5 items-center justify-between'>
                                                <div className='flex items-center gap-2 truncate'>
                                                  <div
                                                    className={cn(
                                                      'flex justify-center items-center rounded-md h-7 aspect-square',
                                                      node?.type === NodeType.Warehouse
                                                        ? 'bg-fuchsia-50 text-fuchsia-900'
                                                        : 'bg-blue-50 text-blue-900',
                                                      {
                                                        invisible: !node,
                                                      },
                                                    )}
                                                  >
                                                    <FontAwesomeIcon
                                                      icon={
                                                        node?.type === NodeType.Warehouse
                                                          ? duotone('warehouse-full')
                                                          : node?.type === NodeType.FinalDestination
                                                          ? duotone('flag-checkered')
                                                          : duotone('store')
                                                      }
                                                    />
                                                  </div>
                                                  <div className='font-semibold truncate'>{node?.displayName ?? <>&nbsp;</>}</div>
                                                </div>
                                                {node && (
                                                  <div onClick={(event) => event.stopPropagation()}>
                                                    <Menu
                                                      zIndex={20}
                                                      placement='bottom-start'
                                                      items={getDistributionMenuItems(node, formik)}
                                                    >
                                                      {({ open }) => (
                                                        <button
                                                          type='button'
                                                          onMouseEnter={() => setHoveredMenuButtonNodeId(node.id)}
                                                          onMouseLeave={() => setHoveredMenuButtonNodeId('')}
                                                          className={cn('flex hover:text-inherit', { 'text-zinc-400': !open })}
                                                        >
                                                          <FontAwesomeIcon
                                                            className='h-4 aspect-square'
                                                            icon={solid('ellipsis-vertical')}
                                                          />
                                                        </button>
                                                      )}
                                                    </Menu>
                                                  </div>
                                                )}
                                              </div>
                                              {node?.type !== NodeType.FinalDestination && (
                                                <button
                                                  type='button'
                                                  className={cn(
                                                    'flex flex-col gap-1.5 px-1.5 py-1 rounded-md',
                                                    'border border-transparent',
                                                    node?.type === NodeType.Warehouse ? 'bg-fuchsia-50' : 'bg-blue-50',
                                                    'group/inner',
                                                    {
                                                      invisible: !node,
                                                      [cn(
                                                        node?.type === NodeType.Warehouse ? 'hover:bg-fuchsia-200' : 'hover:bg-blue-200',
                                                        'hover:shadow-[0_0_2px_0_rgba(0,0,0,0.2)]',
                                                      )]: node && !dragState?.inProgress,
                                                      'group-hover/outer:bg-neutral-50':
                                                        node && !dragState?.inProgress && hoveredInnerViewButtonNodeId !== node.id,
                                                      'cursor-move': dragState?.inProgress,
                                                    },
                                                  )}
                                                  onMouseEnter={() => setHoveredInnerViewButtonNodeId(node!.id)}
                                                  onMouseLeave={() => setHoveredInnerViewButtonNodeId('')}
                                                  onClick={(event) => {
                                                    event.stopPropagation();
                                                    savedScroll.current = {
                                                      left: scrollableRef.current!.scrollLeft,
                                                      top: scrollableRef.current!.scrollTop,
                                                    };
                                                    setInnerView({
                                                      name: InnerView.Storage,
                                                      nodeId: node!.id,
                                                    });
                                                  }}
                                                >
                                                  <div className='flex gap-1.5 items-center'>
                                                    <div
                                                      className={cn(
                                                        'rounded-2xl px-1.5 py-0.5 bg-white ',
                                                        node?.type === NodeType.Warehouse ? 'text-fuchsia-900' : 'text-blue-900',
                                                        {
                                                          'group-hover/outer:text-inherit':
                                                            node && !dragState?.inProgress && hoveredInnerViewButtonNodeId !== node.id,
                                                          [node?.type === NodeType.Warehouse
                                                            ? 'group-hover/inner:text-fuchsia-900'
                                                            : 'group-hover/inner:text-blue-900']: node && !dragState?.inProgress,
                                                        },
                                                      )}
                                                    >
                                                      {node?.items.length ?? 0}
                                                    </div>
                                                    <div
                                                      className={cn({
                                                        'group-hover/outer:text-zinc-500':
                                                          node && !dragState?.inProgress && hoveredInnerViewButtonNodeId !== node.id,
                                                        'group-hover/inner:text-inherit': node && !dragState?.inProgress,
                                                      })}
                                                    >
                                                      Stored item{(node?.items.length ?? 0) === 1 ? '' : 's'}
                                                    </div>
                                                  </div>
                                                  {renderBadges(node, formik, { subNodes: true })}
                                                </button>
                                              )}
                                              {renderBadges(node, formik)}
                                            </div>
                                          </div>,
                                        );

                                      return (
                                        <>
                                          {sizeToPositions(formik.values.layoutGrids.distribution).map((position) =>
                                            render(
                                              position,
                                              [...getWarehouses(formik), ...getStores(formik), ...getFinalDestinations(formik)]
                                                .filter(({ displayName }) => displayName)
                                                .find(
                                                  (node) => node.layoutPosition?.x === position.x && node.layoutPosition?.y === position.y,
                                                ),
                                            ),
                                          )}
                                          {[...getWarehouses(formik), ...getStores(formik), ...getFinalDestinations(formik)]
                                            .filter(
                                              (node) =>
                                                !node.layoutPosition ||
                                                node.layoutPosition.x >= formik.values.layoutGrids.distribution.width ||
                                                node.layoutPosition.y >= formik.values.layoutGrids.distribution.height,
                                            )
                                            .map((node) => render(undefined, node))}
                                        </>
                                      );
                                    })()
                                  )}
                                </div>
                              </div>
                              {final && (
                                <>
                                  <div className='flex flex-col rounded-lg bg-white'>
                                    <div className='flex flex-col justify-center items-center gap-4 py-4 rounded-t-lg bg-[#E8EAF5]'>
                                      <div className='text-zinc-700 font-semibold'>Use</div>
                                      <div className='flex gap-2'>{renderUseAddButtons(formik)}</div>
                                    </div>
                                    <div ref={lifeCycleBoxRefs.current[LifeCycleStage.Use]} className='flex flex-col gap-10 p-6'>
                                      {getConsumptionLocations(formik).length === 0 && (
                                        <div className='text-zinc-400 text-center'>
                                          <LifecycleStagePlaceholder stage={LifeCycleStage.Use} />
                                        </div>
                                      )}
                                      {sortAndFilterNodes(getConsumptionLocations(formik), formik, { ignoreLessImportantValidation })
                                        .flatMap((location) => [
                                          location,
                                          ...getPackagings(formik)
                                            .slice(Math.floor(getPackagings(formik).length / 2) + 1)
                                            .map((packaging) => ({
                                              id: `${location.id}.${packaging.id}`,
                                              displayName: packaging.displayName,
                                            })),
                                        ])
                                        .map((locationOrGap) => {
                                          const location = getConsumptionLocations(formik).some(({ id }) => id === locationOrGap.id)
                                            ? (locationOrGap as ConsumptionNode)
                                            : undefined;
                                          return (
                                            <ConsumptionLocationDetails
                                              key={locationOrGap.id}
                                              data={location}
                                              onSave={(params) => onEditFromMulti(params, formik)}
                                            >
                                              <div className={cn('relative', { 'pointer-events-none': waiting })}>
                                                {location?.flagged && (
                                                  <FontAwesomeIcon
                                                    icon={solid('flag')}
                                                    className='absolute -right-4 text-brandDarkPurple2 pointer-events-none'
                                                  />
                                                )}
                                                <div
                                                  ref={nodeRefs.current[locationOrGap.id]}
                                                  className={cn(
                                                    'text-zinc-700 cursor-pointer flex-1 flex flex-col gap-3 rounded-xl p-1.5',
                                                    'transition',
                                                    'group/outer',
                                                    getNodeStyle(location),
                                                    {
                                                      'hover:bg-cyan-50': ![hoveredInnerViewButtonNodeId, hoveredMenuButtonNodeId].includes(
                                                        locationOrGap.id,
                                                      ),
                                                      invisible: !location,
                                                    },
                                                  )}
                                                >
                                                  <div className='flex gap-0.5 items-center justify-between'>
                                                    <div className='flex gap-2 items-center truncate'>
                                                      <div className='flex justify-center items-center bg-cyan-50 text-cyan-900 rounded-md h-7 aspect-square'>
                                                        <FontAwesomeIcon icon={duotone('house-user')} />
                                                      </div>
                                                      <div className='font-semibold truncate'>{locationOrGap.displayName}</div>
                                                    </div>
                                                    {location && (
                                                      <div onClick={(event) => event.stopPropagation()}>
                                                        <Menu
                                                          zIndex={20}
                                                          placement='bottom-start'
                                                          items={getConsumptionMenuItems(location, formik)}
                                                        >
                                                          {({ open }) => (
                                                            <button
                                                              type='button'
                                                              onMouseEnter={() => setHoveredMenuButtonNodeId(location.id)}
                                                              onMouseLeave={() => setHoveredMenuButtonNodeId('')}
                                                              className={cn('flex hover:text-inherit', { 'text-zinc-400': !open })}
                                                            >
                                                              <FontAwesomeIcon
                                                                className='h-4 aspect-square'
                                                                icon={solid('ellipsis-vertical')}
                                                              />
                                                            </button>
                                                          )}
                                                        </Menu>
                                                      </div>
                                                    )}
                                                  </div>
                                                  {location && (
                                                    <button
                                                      type='button'
                                                      className={cn(
                                                        'flex flex-col gap-1.5 bg-cyan-50 px-1.5 py-1 rounded-md',
                                                        'hover:bg-cyan-200 hover:shadow-[0_0_2px_0_rgba(0,0,0,0.2)]',
                                                        'border',
                                                        location.steps.some((step) =>
                                                          hasValidationMessage(step, formik, {
                                                            ignoreLessImportant: ignoreLessImportantValidation,
                                                          }),
                                                        )
                                                          ? 'border-red-500'
                                                          : 'border-transparent',
                                                        'group/inner',
                                                        {
                                                          'group-hover/outer:bg-neutral-50': hoveredInnerViewButtonNodeId !== location.id,
                                                        },
                                                      )}
                                                      onMouseEnter={() => setHoveredInnerViewButtonNodeId(location.id)}
                                                      onMouseLeave={() => setHoveredInnerViewButtonNodeId('')}
                                                      onClick={(event) => {
                                                        event.stopPropagation();
                                                        savedScroll.current = {
                                                          left: scrollableRef.current!.scrollLeft,
                                                          top: scrollableRef.current!.scrollTop,
                                                        };
                                                        setInnerView({ name: InnerView.ConsumptionLocation, nodeId: location.id });
                                                      }}
                                                    >
                                                      <div className='flex gap-1.5 items-center'>
                                                        <div
                                                          className={cn(
                                                            'rounded-2xl px-1.5 py-0.5 bg-white text-cyan-900 group-hover/inner:text-cyan-900',
                                                            {
                                                              'group-hover/outer:text-inherit':
                                                                hoveredInnerViewButtonNodeId !== location.id,
                                                            },
                                                          )}
                                                        >
                                                          {location.steps.length}
                                                        </div>
                                                        <div
                                                          className={cn('group-hover/inner:text-inherit', {
                                                            'group-hover/outer:text-zinc-500': hoveredInnerViewButtonNodeId !== location.id,
                                                          })}
                                                        >
                                                          Preparation step{location.steps.length === 1 ? '' : 's'}
                                                        </div>
                                                      </div>
                                                      {renderBadges(location, formik, { subNodes: true })}
                                                    </button>
                                                  )}
                                                  {renderBadges(location, formik)}
                                                </div>
                                              </div>
                                            </ConsumptionLocationDetails>
                                          );
                                        })}
                                    </div>
                                  </div>
                                  <div className='flex flex-col rounded-lg bg-white'>
                                    <div className='flex flex-col justify-center items-center gap-4 py-4 rounded-t-lg bg-[#E8EAF5]'>
                                      <div className='text-zinc-700 font-semibold'>End of Life</div>
                                      <div className='flex px-2 h-8 items-center text-center text-zinc-500'>
                                        This section will be filled out automatically.
                                      </div>
                                    </div>
                                    <div ref={lifeCycleBoxRefs.current[LifeCycleStage.EndOfLife]} className='flex flex-col gap-5 p-6'>
                                      {getDisposals(formik).length === 0 && (
                                        <div className='text-zinc-400 text-center'>
                                          <LifecycleStagePlaceholder stage={LifeCycleStage.EndOfLife} />
                                        </div>
                                      )}
                                      {sortBy(getDisposals(formik), [
                                        ({ id }) => getConsumptionLocations(formik).find(({ edges }) => edges.includes(id))?.displayName,
                                        ({ displayName }) => displayName,
                                      ])
                                        .filter(({ displayName }) => displayName)
                                        .filter(({ packagingNodeId }) => getPackagings(formik).some(({ id }) => id === packagingNodeId))
                                        .map((disposal) => (
                                          <DisposalDetails key={disposal.id} data={disposal} onSave={(params) => onEdit(params, formik)}>
                                            <div className={cn('relative', { 'pointer-events-none': waiting })}>
                                              {disposal.flagged && (
                                                <FontAwesomeIcon
                                                  icon={solid('flag')}
                                                  className='absolute -right-4 text-brandDarkPurple2 pointer-events-none'
                                                />
                                              )}
                                              <div
                                                ref={nodeRefs.current[disposal.id]}
                                                className={cn(
                                                  'text-zinc-700 cursor-pointer flex-1 flex flex-col gap-3 rounded-xl p-1.5',
                                                  'transition',
                                                  getPrimaryTag(disposal, formik) === Tag.Default
                                                    ? 'hover:bg-slate-100'
                                                    : 'hover:bg-slate-50',
                                                  getNodeStyle(disposal),
                                                )}
                                              >
                                                <div className='flex gap-2 items-center'>
                                                  <div
                                                    className={cn(
                                                      'flex justify-center items-center text-slate-900 rounded-md h-7 aspect-square',
                                                      getPrimaryTag(disposal, formik) === Tag.Default ? 'bg-slate-100' : 'bg-slate-50',
                                                    )}
                                                  >
                                                    <FontAwesomeIcon icon={duotone('dumpster')} />
                                                  </div>
                                                  <div className='font-semibold truncate'>{disposal.displayName}</div>
                                                </div>
                                                {renderBadges(disposal, formik)}
                                              </div>
                                            </div>
                                          </DisposalDetails>
                                        ))}
                                    </div>
                                  </div>
                                </>
                              )}
                            </>
                          )}
                        </div>
                        {getTransports(formik).map((transport) => (
                          <TransportLink
                            key={transport.id}
                            disabled={!!dragState}
                            nodeRefs={nodeRefs}
                            lifeCycleBoxRefs={lifeCycleBoxRefs}
                            scrollableRef={scrollableRef}
                            fromId={getPrimaryAndMaterialSuppliers(formik).find(({ edges }) => edges.includes(transport.id))!.id}
                            toId={transport.edges[0]}
                            transport={transport}
                            ignoreLessImportantValidation={ignoreLessImportantValidation}
                            onSave={(params) => onSaveTransport(params, formik)}
                            onDelete={() => {
                              remove(transport, formik);
                            }}
                          />
                        ))}
                        {getConsumptionLocations(formik)
                          .flatMap((location) => location.edges.map((edgeId) => ({ ...location, edgeId })))
                          .map((location) => (
                            <TransportLink
                              key={location.edgeId}
                              disabled={!!dragState}
                              nodeRefs={nodeRefs}
                              lifeCycleBoxRefs={lifeCycleBoxRefs}
                              scrollableRef={scrollableRef}
                              fromId={location.id}
                              toId={location.edgeId}
                              ignoreLessImportantValidation={ignoreLessImportantValidation}
                            />
                          ))}
                      </>
                    )}
                    <div className='h-36 shrink-0'></div>
                  </>
                )}
              </div>
              <div className={padding}></div>
            </>
          );
        })()}
      </div>
      {layout === Layout.Graph && !innerView && (
        <button type='button' className='group absolute bottom-4 z-20 ml-6 rounded-full border shadow-[0px_1px_10px_rgba(79,_0,_255,_0.3)]'>
          <TransportDetails onSave={(params) => onSaveTransport(params, formik)}>
            <div className='flex items-center rounded-full relative bg-brand'>
              <div className='flex justify-center items-center bg-brand h-12 aspect-square rounded-full z-10 group-hover:cursor-pointer'>
                <FontAwesomeIcon className='h-5 aspect-square text-white' icon={duotone('truck')} />
              </div>
              <div
                className={cn(
                  'flex items-center justify-center bg-brand',
                  '-left-6 transition-width w-0 group-hover:w-40 h-12 duration-300',
                  'relative rounded-r-full whitespace-nowrap',
                  'group-hover:cursor-pointer',
                )}
              >
                <div className='ml-8 text-white w-0 group-hover:w-full overflow-hidden font-semibold transition-opacity delay-100 duration-200 opacity-0 group-hover:opacity-100'>
                  New transport link
                </div>
              </div>
            </div>
          </TransportDetails>
        </button>
      )}
    </div>
  );
};
